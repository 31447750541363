.fondo-formulario-inscripcion {
  position: absolute; /* Para colocar el fondo en la posición adecuada */
  top: 0;
  left: 0;
  width: 100%; /* Abarca todo el ancho */
  height: 70%; /* Abarca la mitad del viewport */
  background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen */
}

.fondo-formulario-inscripcion::before {
  content: ''; /* Necesario para mostrar un pseudo-elemento */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Abarca todo el ancho del contenedor */
  height: 100%; /* Abarca toda la altura del contenedor */
  background-color: #741919a4; /* Color con transparencia */
  z-index: 0; /* Capa por encima de la imagen pero detrás del contenido */
}

.titulopriForm h1 {
  position: relative; /* Posiciona el contenido sobre el fondo */
  z-index: 2; /* Asegura que esté por encima del fondo */
  display: flex; /* Centra contenido horizontal y verticalmente */
  justify-content: center;
  margin-bottom: 15px;;
  margin-top: 270px; /* Ajusta la posición vertical */
  text-align: center;
  color: rgb(243, 208, 66); /* Hace que el texto sea legible sobre el fondo */
  font-size: 2.3rem; /* Tamaño adecuado para un título importante */
  font-weight: 400;
  padding-left: 80px; /* Espacio a la izquierda */
  padding-right: 80px; /* Espacio a la derecha */
  
}

.mensaje-obligatorio {
  font-size: 1.1rem; /* Tamaño del texto adecuado */
  position: relative; /* Posiciona el contenido sobre el fondo */
  z-index: 2; /* Asegura que esté por encima del fondo */
  display: flex; /* Centra contenido horizontal y verticalmente */
  justify-content: center;
  padding-left: 80px;
  padding-right: 100px;
  text-align: center;
  color: rgb(236, 224, 177); /* Hace que el texto sea legible sobre el fondo */
}

.boton-formulario {
  margin: 10px 5px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #D7852C;
  color:beige;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 4;
  
}

.boton-formulario:hover {
  background-color: #f3cca2;
  color: rgb(16, 0, 0);
}

.botones-formularios {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.formulario-inscripcion {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  color: white;
  background-color: white;
  box-shadow: 0 4px 15px rgba(9, 9, 9, 0.1);
  margin-bottom: 50px; /* Espaciado inferior */
  margin-top: 500px; /* Espaciado superior */
}

.header-inscripcion {
  text-align: center; /* Centra el texto horizontalmente */
  margin-top: 20px;
  margin-bottom: 40px; /* Espacio inferior adicional */
  height: 30px;
  position: relative; /* Necesario para que el z-index funcione */
}

.header-inscripcion::after {
  content: ''; /* Crea la línea decorativa */
  position: absolute;
  bottom: 1px; /* Reduce la distancia entre la línea y el texto */
  left: 50%; /* Comienza desde el centro */
  transform: translateX(-50%); /* Centra la línea horizontalmente */
  width: 900px; /* Controla el ancho de la línea */
  height: 70px; /* Grosor de la línea */
  background-color: #870404; /* Color del subrayado */
  border-radius: 5px; /* Opcional: redondea los bordes de la línea */
  z-index: 1; /* Asegura que la línea decorativa esté detrás del texto */
}

.header-inscripcionFor h2 {
  display: flex; /* Activa Flexbox para el contenedor del texto */
  justify-content: center; /* Centra el texto horizontalmente */
  align-items: center; /* Centra el texto verticalmente */
  margin: 0; /* Elimina cualquier margen que desplace el texto */
  position: relative; /* Necesario para que el z-index funcione */
  z-index: 10; /* Asegura que el título esté por encima del recuadro morado */
  color: white; /* Cambia el color del texto a blanco */
  font-size: 20px; /* Tamaño del texto */
  transform: translateY(-15px); /* Sube el texto 10px */
  font-weight: 200;
}



form {
  display: flex;
  flex-direction: column;
}

.seccion-formulario {
  margin-bottom: 30px;
}

.seccion-formulario h2 { 
  color: #870404;
  margin-bottom: 20px; /* Añadir espacio inferior */
  margin-top: 40px;    /* Añadir espacio superior para separación */
}
.seccion-formulario h3 { 
  color: #870404;
  margin-bottom: 20px; /* Añadir espacio inferior */
  margin-top: 40px;    /* Añadir espacio superior para separación */
}

.seccion-formulario h4 {
  color: #cc0000; /* Rojo */
  font-weight: normal; /* Quitar negrita */
  font-size: 1.1rem; /* Ajustar tamaño de texto si es necesario */
  margin-top: 20px; /* Espaciado superior */
  margin-bottom: 20px; /* Espaciado inferior */
  margin-top: -12px;    /* Añadir espacio superior para separación */
  
}

.error {
  color: red;
  font-size: 1rem;
  font-weight: normal; /* Quitar la negrita */
  margin-top: -4px; /* Ajustar el margen superior */
}

.grid-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

label {
  display: block;
  margin-bottom: 15px;
  font-size: 1rem;
  color: #333;
}

input[type="text"],
input[type="email"],
input[type="date"],
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #b4c9ea;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

/* Cambiamos la disposición vertical a grid (2 columnas, 2 filas) */

input[type="file"]:invalid {
  border: 2px solid red;
}


.mi-lista {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
}

.mi-lista-item {
  padding: 8px;
  cursor: pointer;
}

.mi-lista-item:hover {
  background-color: #818182;
  color: white;
}

select {
  height: 45px;
  font-size: 16px;
  padding: 20px;
  border-radius: 5px; /* Bordes redondeados */
  border: 1px solid #c8cef6; /* Color del borde */
  gap: 10px; /* Espacio hacia abajo */
}


.subir-documentos-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dos columnas */
  gap: 20px; /* Espacio entre columnas y filas */
  justify-items: center; /* Centra los íconos horizontalmente */
}

.subir-documento {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; /* Alinea el texto al centro */
}

.imagen-subir {
  width: 100px;   /* Ajusta el tamaño de la imagen aquí */
  height: 100px;  /* Ajusta el tamaño de la imagen aquí */
  cursor: pointer;
  margin-top: 20px; /* Añade margen superior para bajar la imagen */
}

.subir-documento span {
  position: relative;
  margin-left: -12px;  /* Ajusta este valor para separar el texto a la izquierda */
  font-size: 1rem;
  color: #333;
  font-weight: normal;  /* Sin negrita */
}
/***************************************************************************************************************************************/
/* Estilo de los iconos SVG */
.icono-usuario,
.icono-subir,
.icono-confirmacion {
  width: 30px;
  height: 30px;
  color: white;
}
.icono-carnet {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Contenedor de la barra de progreso */
.barra-progreso {
  display: flex;
  justify-content: space-between; /* Distribuye los pasos horizontalmente */
  align-items: center; /* Alinea verticalmente los círculos */
  width: 100%; /* Ocupa todo el ancho disponible */
  position: relative;
  
}

/* Línea base que conecta los pasos (fondo de la línea) */
.barra-progreso::before {
  content: '';
  position: absolute;
  top: 50%; /* Centra la línea verticalmente */
  left: 0;
  right: 0; /* Extiende la línea a todo el contenedor */
  height: 3px; /* Grosor de la línea */
  background-color: #f4d3b0; /* Color de las líneas inactivas */
  z-index: 0; /* Envía la línea detrás de los círculos */
  transform: translateY(-50%);
}

/* Estilo de cada paso */
.paso {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #f4d3b0; /* Fondo de los pasos inactivos */
  border-radius: 50%; /* Hace que sean círculos */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Los pasos están sobre las líneas */
}
/* Líneas activas entre los pasos */
.paso.activo::after {
  content: '';
  position: absolute;
  top: 50%;
  left: -85%; /* La línea empieza desde el centro del paso */
  width: 220px; /* Extiende la línea hasta el siguiente paso */
  height: 3px; /* Grosor de la línea */
  background-color:  #D7852C; /* Color de las líneas activas */
  z-index: 1; /* Línea por encima de la línea base */
  transform: translateX(42%) translateY(-50%);
}
/* El primer paso no tiene línea antes de él */
.paso:first-child::before {
  content: none;
}
.paso:last-child::after {
  content: none; /* Elimina la línea después del último paso */
}

/* Iconos dentro de los pasos */
.paso svg {
  width: 24px;
  height: 24px;
  stroke: white; /* Color de los íconos */
}

/* Estado activo del paso */
.paso.activo {
  background-color: #D7852C; /* Fondo del paso activo */
  color: white;
}


/****************************************************************************************************************************************/

/* Botón Anterior alineado a la izquierda */
.boton-anterior {
  margin-right: auto; /* Empuja el botón hacia la izquierda */
}

/* Botón Siguiente alineado a la derecha */
.boton-siguiente {
  margin-left: auto; /* Empuja el botón hacia la derecha */
}
/* Botón Verificar CI */
.boton-verificaronl {
  display: inline-block; /* Asegura que el botón sea visible */
  padding: 10px 20px; /* Espaciado interno */
  background-color: #870404; /* Color de fondo */
  color: white; /* Color del texto */
  border: none; /* Sin bordes */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  font-size: 1rem; /* Tamaño del texto */
  margin-top: 30px;
}

.boton-verificaronl:hover {
  background-color: #bc7878; /* Color más claro al pasar el mouse */
}


/* Botones de navegación */
.boton-anterioronl, .boton-siguienteonl {
  display: inline-block; /* Asegura que los botones sean visibles */
  padding: 12px 25px; /* Aumenta el tamaño de los botones */
  background-color: #870404; /* Color de fondo */
  color: white; /* Color del texto */
  border: none; /* Sin bordes */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  font-size: 1.1rem; /* Tamaño del texto */
  height: 40px; /* Ajusta a la altura que prefieras */
  margin-top: 30px;
}

.boton-siguienteonl {
  margin-left: auto; /* Empuja el botón hacia la derecha */
}

.boton-anterioronl:hover, .boton-siguienteonl:hover {
  background-color: #ad7171; /* Color más claro al pasar el mouse */
}

/* Contenedor de navegación */
.navegaciononl {
  display: flex; /* Alinea los botones horizontalmente */
  justify-content: space-between; /* Espacia los botones a los lados */
  align-items: center; /* Centra los botones verticalmente */
  gap: 20px; /* Espacio entre los botones */
  width: 100%; /* Asegura que el contenedor ocupe todo el ancho disponible */
}

/* Estilos para el botón "Enviar Inscripción" */
.boton-enviar-inscripcion {
  background-color: #FFC834; /* Color de fondo */
  color: white; /* Color del texto */
  border: none; /* Sin bordes */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  padding: 10px 20px; /* Espaciado interno */
  font-size: 1rem;
  height: 40px; /* Ajusta la altura */
  margin-top: 30px;
  
}

.boton-enviar-inscripcion:hover {
  background-color: #f7e5b5; /* Color más oscuro al pasar el mouse */
}

/* Contenedor de los botones finales */
.navegacion-final {
  display: flex; /* Flexbox para alinear los botones */
  justify-content: space-between; /* Espacia los botones a los extremos */
  align-items: center; /* Centra verticalmente los botones */
  margin-top: 30px; /* Espacio superior */
  width: 100%; /* Asegura que ocupe todo el ancho */
}

/* Botón "Anterior" */
.boton-anterior-final {
  padding: 7px 25px; /* Tamaño del botón */
  background-color: #870404; /* Fondo */
  color: white; /* Texto */
  border: none; /* Sin bordes */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  font-size: 1.1rem; /* Tamaño del texto */
  height: 40px; /* Ajusta la altura */
  transition: background-color 0.3s; /* Transición para hover */
}

.boton-anterior-final:hover {
  background-color: #ad7171; /* Fondo más claro al pasar el mouse */
}

/* Botón "Enviar" */
.boton-enviar-final {
  background-color: #E4A232; /* Fondo amarillo */
  color: white; /* Texto */
  border: none; /* Sin bordes */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  padding: 7px 25px; /* Tamaño del botón */
  font-size: 1.1rem; /* Tamaño del texto */
  height: 40px; /* Ajusta la altura */
  margin-left: 80px; /* Margen izquierdo */
  transition: background-color 0.3s; /* Transición para hover */
}

.boton-enviar-final:hover {
  background-color: #f7e5b5; /* Fondo más claro al pasar el mouse */
  color: black;
}

select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
}
