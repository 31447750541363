@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.pie-pagina {
  background:  #f4f4f4 ;
  color: #666565; 
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  
}

/*Contenedor principal (4 columnas)*/
.pie-pagina-contenido {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 50px; 
  width: 100%;
  max-width: 1400px; 
  padding: 40px 10px; 
  margin: 0 auto; 
}

/* -------------------------------
   Columna 1: Logo y descripción
-------------------------------- */
.pie-pagina-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Lato', sans-serif;

}

.pie-pagina-logo img {
  width: 150px;
  margin-bottom: 20px;
}
.logos-container {
  display: flex; 
  align-items: center; 
  justify-content: center; 
  gap: 10px; 
}

.logo-senabol, .logo-sirai {
  width: 90px;
  height: auto;
}


.texto-justificadolo {
  text-align: justify;
  font-size: 1rem;
  margin-top: -15px;
}

.pie-pagina-social {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.pie-pagina-social a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #7F1618; 
  color: white; 
  border-radius: 50%; 
  text-decoration: none;
  font-size: 1.3rem;
  transition: transform 0.3s ease;
  margin-top: 15px;
}

.pie-pagina-social a:hover {
  transform: scale(1.1); 
}

/* -------------------------------
   Columna 2 y 3: Enlaces (Asociados y Red Institucional)
-------------------------------- */
.pie-pagina-info {
  display: flex;
  flex-direction: column;
  gap: 30px; 
  font-family: 'Lato', sans-serif;
}

.pie-pagina-info ul {
  list-style: none;
  padding: 0;
}

.pie-pagina-info li {
  margin-bottom: 8px;
}

.pie-pagina-info a {
  text-decoration: none;
  color: #767272; 
}

.pie-pagina-info a:hover {
  color: #E4A232; 
  text-decoration: underline; 
}


.pie-pagina-info ul li a::before {
  
  color: #76b6b6; 
  margin-right: 8px;
  font-size: 1rem;
  display: inline-block;
  vertical-align: middle;
}

.titulo-explorar {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 100;
}

/* -------------------------------
   Columna 4: Contacto
-------------------------------- */
.pie-pagina-contacto {
  display: flex;
  flex-direction: column;
  gap: 10px; 
  font-family: 'Lato', sans-serif;
  color: #1c1b1b;
  text-align: left; 
  font-weight: 300; 
}


.pie-pagina-contacto h4 {
  font-size: 1.1rem;
  color: #951a1c; 
  font-weight: 300;
}

.titulo-explorar h4{
  font-size: 1.1rem;
  color: #951a1c; 
  font-weight: 300; 
}

.pie-pagina-contacto p {
  font-size: 1rem;
  color: #7d7d7d; 
  line-height: 1.5; 
  text-align: left; 
  font-weight: 300; 
}

/* -------------------------------
   Mapa (Debajo de las columnas)
-------------------------------- */
.pie-pagina-mapa {
  margin-top: 20px; 
  width: 100%;
  padding: 20px; 
}

.pie-pagina-mapa iframe {
  width: 100%;
  height: 400px;
  border: 0;
}

/* -------------------------------
   Copyright (al final del pie de página)
-------------------------------- */
.pie-pagina-copyright {
  width: 100%; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  padding: 10px 10px;
  font-size: 1rem; 
  background-color: #f8f2f7; 
  padding: 20px; 
 color: #817f7f; 
  height: 100px; 
  border-top: 1.5px solid #c5c2c2; 
  max-width: 80%;
  margin: 0 auto; 
}

.pie-pagina-copyright .corazon {
  color: #817f7f; 
  font-size: 1.1rem; 
  margin-left: 3px; 
  margin-right: 3px; 
}


.pie-pagina-copyright .nombre {
  color: #c63598; 
  
}
