/* src/Componentes-Restringidos/ModuloPanelDeDatos/PanelDeDatos.css */
.panel-datos-container {
  padding: 20px;                    /* Espacio interno dentro del contenedor */
  background-color: #f4f4f4;        /* Color de fondo */
  border-radius: 10px;              /* Bordes redondeados */
  margin: 20px;                     /* Margen general alrededor del contenedor */
  margin-top: 75px;                /* Margen superior de 100px */
  margin-left: 300px;               /* Margen izquierdo de 300px */
  width: 80%;                       /* Ocupará el 80% del ancho disponible */
}



.panel-datos-titulo {
  text-align: center;
  font-size: 10px;
  margin-bottom: 20px;
  color: #333;
}



/* Estilo del texto del título */
.titulo-texto {
  font-size: 20px;
  color: #5d647b; /* Color del texto del título */
  margin-left: -570px; /* Mueve el texto hacia la izquierda */
  margin-top: -28px; /* Sube el título 10px */
  margin-bottom: 22px;
  text-align: center;
  font-weight: bold; /* Aplica negrita */
}

.grafico-contenedor {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-left: 180px; /* Ajusta al ancho del menú lateral */
}

.grafico-barra, .grafico-pie {
  width: 45%;
  margin-bottom: 20px;
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 270px; /* Ajusta la altura aquí según tus necesidades */
}


.grafico-barra h3, .grafico-pie h3 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 15px;
}


/* Estilo para el ícono */
.icono-titulo {
  font-size: 25px;   /* Tamaño del ícono */
  color: #5d647b;       /* Color del ícono */
  margin-right: 10px; /* Espacio entre el ícono y el texto */
  margin-left: 260px; /* Mueve el ícono hacia la derecha 225px */
  margin-top: 18px;   /* Baja el ícono 50px */
  position: relative; /* Establece la posición para permitir el uso de z-index */
  z-index: 10;        /* Asegura que el ícono esté al frente */
}

/* Estilo de la línea divisoria */
.linea-divisoria {
  border: none;
  border-bottom: 2px solid #333; /* Color y grosor de la línea */
  margin-top: -10px; /* Ajusta este valor para subir la línea */
  margin-bottom: 20px; /* Espacio debajo de la línea */
  width: 80%; /* Ajusta el ancho de la línea */
  margin-left: 260px; /* Mueve la línea hacia la derecha */
}

