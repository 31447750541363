@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

/* Sección Principal */
.seccion-destacados {
  padding: 80px 20px;
  text-align: center;
  position: relative;
  background-size: cover;
  background-position: center;
  height: auto;
  margin-top: 20px;
  background-attachment: fixed;
  border-radius: 15px; /* Bordes redondeados */
}

/* Rectángulo semitransparente */
.rectangulo-transparente {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(59, 1, 1, 0.5);
  z-index: 1;
}

/* Encabezado y contenedor */
.destacados-header, .destacados-container {
  position: relative;
  z-index: 2;
}

.destacados-header h2 {
  color: white;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 2rem;
  letter-spacing: 1px;
}

/* Contenedor de tarjetas */
.destacados-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}



.destacado:hover,
.destacado:focus-within {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.destacado img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.destacado:hover img {
  transform: scale(1.1);
}

.destacado-texto {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  color: #0c0000;
  padding: 15px 0;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  text-align: center;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
}

/* Círculos y estrellas flotantes */
.floating-circle {
  width: 30px;
  height: 30px;
  border: 4px solid rgba(250, 248, 251, 0.4);
  border-radius: 50%;
  position: absolute;
  animation: float 8s ease-in-out infinite;
  z-index: 1;
}

.floating-circle.circle-medium {
  width: 50px;
  height: 50px;
  top: 20%;
  left: 5%;
}

.floating-circle.circle-large {
  width: 70px;
  height: 70px;
  bottom: 15%;
  right: 10%;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(100px, -100px);
  }
  50% {
    transform: translate(-50px, 100px);
  }
  75% {
    transform: translate(-100px, -50px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.floating-star {
  width: 30px;
  height: 30px;
  background-color: rgba(227, 187, 208, 0.8);
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  position: absolute;
  animation: floatStar 8s ease-in-out infinite;
  z-index: 1000;
}

.floating-star.star-small {
  width: 20px;
  height: 20px;
  top: 30%;
  left: 85%;
}

@keyframes floatStar {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(80px, -80px) rotate(45deg);
  }
  50% {
    transform: translate(-60px, 80px) rotate(90deg);
  }
  75% {
    transform: translate(-100px, -60px) rotate(135deg);
  }
  100% {
    transform: translate(0, 0) rotate(180deg);
  }
}

/******************************************************************************/
/* Contenedor de miniaturas de video */


.video-modal-content {
  position: relative;
  width: 100%;
  width: 1200px;
 
}

/* Estilo para el botón "X" */
.close-modal {
  position: absolute;
  top: 20px;    /* Ajustamos el botón un poco más arriba */
  right: 5px;  /* Ajustamos el botón más cerca de la esquina derecha */
  font-size: 30px;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 2; /* Asegura que el botón esté por encima del contenido */
}


/* Estilos para el video */
video {
  width: 100%;
  height: auto;
}
.video-thumbnail {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Asegura que el contenido que se desborda se oculte */
}

/* Ajustamos las imágenes para que no se deformen */
.video-imagen {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Esto asegura que las imágenes se recorten si es necesario para mantener la proporción */
  display: block; /* Para evitar el espacio extra debajo de las imágenes */
}

/* Establecer una altura fija para las miniaturas */
.destacado {
  position: relative;
  width: 340px;
  height: 390px; /* Altura fija para las miniaturas */
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 1;
}

/* Resto del estilo permanece igual */
.destacado:hover,
.destacado:focus-within {
  transform: scale(1.05);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.destacado-texto {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  color: #0c0000;
  padding: 15px 0;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  text-align: center;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
}

.play-buttonDes {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.triangle {
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 20px solid #620303; /* Triángulo apuntando hacia la derecha */
}

