/* Contenedor principal */
/* Contenedor principal */
.ContenedorPrincipalOfe {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se envuelvan en líneas adicionales si no hay espacio */
  justify-content: space-around; /* Espacio uniforme entre los elementos */
  gap: 20px; /* Espaciado entre los elementos */
  padding: 20px; /* Espaciado interno para separar los elementos del borde del contenedor */
  background-color: #a01212; /* Color de fondo */
  border-radius: 8px; /* Bordes redondeados */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  align-items: flex-start; /* Alinea los elementos en la parte superior si tienen diferentes alturas */
}


/* Sección principal */
.oferta-academica {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, #ECDCCB 10%, #571001 60%);
  padding: 80px;
  color: #F9FAFB;
  position: relative;
  overflow: hidden;
  height: 600px; /* Altura inicial */
}

/* Contenido principal */
.oferta-content {
  max-width: 45%;
  z-index: 2;
  padding-left: 0px;
  margin-top: 30px;
  padding-top: 50px; /* Reducido para disminuir la altura */
}



/* Título */
.oferta-content h1 {
  font-size: 40px;
  font-weight: 700;
  color: #f6f7dc;
  margin: 12px 0;
  font-family: 'Roboto Slab', sans-serif;

}

/* Texto descriptivo */
.oferta-content p {
  font-size: 18px;
  text-align: left;
  color: #E9DEC2;
  margin: 20px 0;
  font-family: 'Roboto';
}

/* Enlace */
.credit-link {
  color: #ffffff;
  text-decoration: underline;
  margin-bottom: 20px;
  display: inline-block;
}

/* Botón principal */
.cta-button {
  background-color:#E4A232;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #eed3a4;
  color: #333;
}
/* Imagen del mockup */
.laptop-mockup {
  position: absolute;
  right: 6%; /* Alineación derecha */
  width: 40%;
  height: auto;
  margin-top: 75px;
  z-index: 1;
}

.laptop-mockup img {
  width: 150rem;
  height: auto;
}

/* Fondo decorativo */



/* Responsividad */

/* Para pantallas medianas */
@media (max-width: 992px) {
  .oferta-academica {
    flex-direction: column; /* Apila el contenido verticalmente */
    padding: 40px;
    height: auto; /* Ajusta la altura automáticamente */
  }

  .oferta-content {
    max-width: 100%; /* Ocupa todo el ancho */
    text-align: center; /* Centra el texto */
    padding-left: 0; /* Elimina padding izquierdo */
  }

  .laptop-mockup {
    position: static; /* Cambia a posición estática */
    margin-top: 20px; /* Añade margen superior */
    width: 80%; /* Reduce el tamaño */
  }

  .oferta-content h1 {
    font-size: 2.5rem; /* Reduce el tamaño del título */
  }

  .oferta-content p {
    font-size: 1rem; /* Reduce el tamaño del texto */
  }
}

/* Para pantallas pequeñas */
@media (max-width: 768px) {
  .oferta-content {
    padding: 20px; /* Reduce padding */
    margin-top: 300px;
  }

  .cta-button {
    width: 100%; /* Botón ocupa todo el ancho */
    padding: 10px; /* Ajusta padding */
  }

  .laptop-mockup {
    width: 90%; /* Mockup más pequeño */
  }
}

/* Para pantallas muy pequeñas */
@media (max-width: 480px) {
  .oferta-content h1 {
    font-size: 2rem; /* Reduce título aún más */
  }

  .oferta-content p {
    font-size: 0.9rem; /* Reduce texto */
  }

  .cta-button {
    font-size: 0.9rem; /* Ajusta tamaño del botón */
  }
}



/*------------------------SECCION PLAN DE ESTUDIOS -----------------------------------------------------*/
.contenedorTarjetasA {
  display: flex; /* Activa flexbox */
  flex-direction: column; /* Coloca elementos (como el título y las tarjetas) en una columna */
  align-items: center; /* Centra horizontalmente todos los elementos */
  justify-content: flex-start; /* Alinea los elementos al inicio verticalmente */
  gap: 20px; /* Espacio entre los elementos */
  padding: 20px; /* Espaciado interno */
  background-color: #F9FAFB; /* Color de fondo */
  max-width: 100%; /* El contenedor ocupa todo el ancho disponible */
  height: 1020px; /* Altura fija */
}

.contenedorTarjetasA h1 {
  font-size: 1.8rem;
  padding-top: 40px;
    font-weight: 700;
    color: #571001;
    margin-bottom: 20px;
    text-align: center;
}



@keyframes growLine {
  from {
    width: 0;
  }
  to {
    width: 100px;
  }
}
/*****************************************************************/
.courses-section {
  display: grid; /* Usamos grid en lugar de flexbox */
  grid-template-columns: repeat(4, 1fr); /* Máximo 4 tarjetas por fila */
  gap: 20px; /* Espaciado entre tarjetas */
  padding: 20px;
  background-color: #F9FAFB;
  margin-top: -10px; /* Ajusta este valor para bajar la sección */
}

.course-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ajustamos al ancho disponible en el grid */
  height: 400px; /* Altura fija para las tarjetas */
}

.course-icon {
  width: 60px; /* Ancho del ícono */
  height: 60px; /* Altura del ícono */
  margin-bottom: 10px; /* Espaciado inferior */
  border-radius: 50%; /* Hace que el ícono sea circular */
  background-color: #7F1618; /* Fondo del icono */
  display: flex; /* Activa flexbox */
  justify-content: center; /* Centra horizontalmente el SVG */
  align-items: center; /* Centra verticalmente el SVG */
  padding: 10px; /* Espaciado interno para ajustar el ícono */
  color: #FFFFFF; /* Cambia el color del icono a blanco */
}

.course-card h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0;
  color: #333;
}

.rating {
  color: #D7852C;
  font-size: 1em;
  margin-bottom: 10px;
}

.rating-score {
  font-size: 0.9em;
  color: #4CAF50;
}

.instructor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

.instructor-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.pricing {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 20px;
}

.enroll-button {
  background-color: #E4A232;
  color: #FFE9C9;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.enroll-button:hover {
  background-color: #f7efe1;
  color: #E4A232;
}

/* Ajustes responsivos */
@media (max-width: 1200px) {
  .courses-section {
    grid-template-columns: repeat(3, 1fr); /* 3 tarjetas por fila en pantallas medianas */
  }
}

@media (max-width: 768px) {
  .courses-section {
    grid-template-columns: repeat(2, 1fr); /* 2 tarjetas por fila en pantallas pequeñas */
  }
}

@media (max-width: 480px) {
  .courses-section {
    grid-template-columns: 1fr; /* 1 tarjeta por fila en pantallas muy pequeñas */
  }
}


/* Responsividad *********************************************/
@media (max-width: 992px) {
  .contenedorTarjetasA {
    padding: 15px;
  }

  .contenedorTarjetasA h1 {
    font-size: 1.8rem; /* Reduce el tamaño del título */
  }

  .courses-section {
    gap: 15px; /* Reduce el espacio entre tarjetas */
  }

  .course-card {
    max-width: 300px; /* Reduce el ancho de las tarjetas */
    padding: 15px; /* Reduce el padding */
  }

  .course-card h3 {
    font-size: 1rem; /* Reduce el tamaño del texto */
  }
}
/*responsivo************************************************/
@media (max-width: 768px) {
  .contenedorTarjetasA {
    display: flex;
    flex-wrap: wrap; /* Permite ajustar las tarjetas en varias filas */
    justify-content: center; /* Centra horizontalmente */
    gap: 20px; /* Espacio entre las tarjetas */
  }

  .contenedorTarjetasA h1 {
    max-width: 100%; /* Asegura que el título ocupe todo el ancho */
    font-size: 1.5rem; /* Reduce el tamaño del título */
  }

  .linea-decorativaA {
    width: 80px; /* Reduce la línea decorativa */
    margin: 0 auto 20px; /* Centra la línea */
  }

  .course-card {
    width: calc(50% - 20px); /* Cada tarjeta ocupa el 50% del contenedor con espacio entre ellas */
    max-width: 400px; /* Asegura un ancho máximo razonable */
    box-sizing: border-box; /* Incluye el padding y borde en el cálculo del ancho */
  }

  .course-icon {
    width: 50px; /* Reduce el ícono */
    height: 50px;
  }
}

@media (max-width: 480px) {
  .contenedorTarjetasA h1 {
    font-size: 1.3rem; /* Ajusta el tamaño del título para pantallas muy pequeñas */
  }

  .linea-decorativaA {
    width: 60px; /* Línea más pequeña */
  }

  .course-card {
    max-width: 250px; /* Ajusta aún más el ancho de las tarjetas */
    padding: 10px; /* Reduce el padding */
  }

  .course-card h3 {
    font-size: 0.9rem; /* Ajusta el tamaño del texto */
  }

  .course-icon {
    width: 40px; /* Ajusta el tamaño del ícono */
    height: 40px;
  }

  .enroll-button {
    padding: 8px 15px; /* Reduce el tamaño del botón */
    font-size: 0.9rem; /* Reduce el tamaño del texto en el botón */
  }
}

