.dashboard-container {
    display: flex;
    flex-direction: column;
  }
  
  .dashboard-content {
    display: flex;
  }
  
  .contenido {
    flex: 1;
    margin-left: 250px; /* Ajustar si la barra lateral tiene otro tamaño */
    padding: 20px;
    margin-top: 70px; /* Para evitar que el contenido quede detrás de la barra superior */
  }
  