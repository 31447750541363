@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Contenedor principal */
.menu-contenedor4Sop {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #ECDCCB 10%, #571001 60%);
}

.header-image-containerNuSop {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px; /* Altura de la sección */
}

.overlayNoSop {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Separa el texto e imagen */
  width: 100%;
  padding: 0 50px; /* Espaciado horizontal */
}

.texto-contenedorSop {
  margin-top: 60px;
  max-width: 50%; /* Limita el ancho del texto */
  color: white;
  margin-right: 20px;
  margin-left: 40px; /* Ajusta la posición hacia la derecha */
}

.texto-contenedorSop p{
  margin-top: 20px;
}

.imagen-derechaSop {
  margin-top: 80px;
  width: 80rem; /* Limita el ancho de la imagen */
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagen-derechaSop img {
  width: 100%; /* La imagen ocupa todo el contenedor */
  height: auto;
  
}

.subtituloSop {
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #FFDD57;
  margin-bottom: 10px;
}

.titulo-degradado2Sop {
  font-size: 40px;
  font-weight: 700;
  color: #f6f7dc;
  margin: 12px 0;
  font-family: 'Roboto Slab', sans-serif;
}

.texto-contenedorSop p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #F5E1DA;
  margin-bottom: 30px;
}

.btn-videoSop {
  background-color: #E4A232;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s;
}

.btn-videoSop:hover {
  background-color: #eed3a4;
}

.fa-play-circle {
  margin-left: 5px;
}

/* Modal de video */
.video-modalSop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Fondo negro translúcido */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-modal-contentNoSop {
  position: relative;
  width: 80%; /* Ajusta el tamaño del video */
  max-width: 1000px; /* Tamaño máximo para pantallas grandes */
  background-color: #000;
  border-radius: 5px;
  overflow: hidden;
}

.video-modal-contentNoSop video {
  width: 100%;
  height: auto;
}

.close-modalSop {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  z-index: 1000;
}

/* Animaciones */
@keyframes fadeInSop {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/******************************************************************************************************/
/* recuadro blanco */
.fondoblancoprincipal {
 
  margin: 10px auto; /* Margen superior e inferior */
  width: 100% ; /* Ajusta el ancho para tener en cuenta el nuevo margen izquierdo */
  max-width: 1400px; /* Ancho máximo para pantallas grandes */
  position: relative; /* Asegura que el contenedor pueda ser movido */
  z-index: 10; /* Trae el contenedor al frente */
  min-height: 1090px; /* Altura mínima del contenedor */
}


   

/* Sección de contacto */
.contact-section {
text-align: center;  /* Centra el texto y los elementos en línea dentro del contenedor horizontalmente */
padding: 50px;       /* Agrega un espacio de 50 píxeles alrededor del contenido en todos los lados */
}

.contact-section h2 {
font-size: 1.3rem;    /* Tamaño de la fuente para el título grande en la sección de contacto */
font-family: 'Roboto';;  /* Establece la fuente específica Bebas Neue */
font-weight: normal; /* Cambia el peso de la fuente para que no sea negrita */
color: #000;         /* Color del texto negro */
}

.contact-section h3 {
font-family: 'Roboto', sans-serif;  /* Usa la misma fuente Bebas Neue para el subtítulo */
font-weight: normal;  /* Establece que el subtítulo no esté en negrita */
font-size: 1.8rem;      /* Tamaño de la fuente para el subtítulo más pequeño que el título principal */
color: #8B0000;       /* Color del texto en rojo oscuro (DarkRed) */
margin-top: 10px;     /* Espaciado superior de 10 píxeles entre el subtítulo y el elemento anterior */
}
.contact-section p {
font-size: 1.2rem;    /* Tamaño de la fuente para el título grande en la sección de contacto */
font-family: 'Roboto', sans-serif; 
font-weight: normal; /* Cambia el peso de la fuente para que no sea negrita */
color: #000;         /* Color del texto negro */
}
.contact-options {
display: flex; /* Activa flexbox */
justify-content: center; /* Centra los elementos horizontalmente */
align-items: flex-start; /* Alinea los elementos en la parte superior */
gap: 40px; /* Espacio entre las cajas */
margin-top: 30px; /* Espacio superior */
}

.contact-box {
text-align: center; /* Centra el texto dentro de cada caja de contacto individual */
max-width: 400px; /* Limita el ancho máximo de cada caja */
display: flex;
flex-direction: column; /* Asegura que los elementos se apilen verticalmente */
align-items: center; /* Alinea los elementos dentro de la caja en el centro */
}

.icon {
font-size: 4rem; /* Ajusta el tamaño del ícono */
color: #8B0000; /* Color rojo oscuro */
margin-bottom: 15px; /* Añade espacio debajo del ícono */
}
.texto-rojo {
color: #7F0000; /* Aplica el color rojo oscuro */
}

.chat-button {
background-color: #25D366; /* Color verde de WhatsApp */
color: white; /* Texto blanco */
padding: 10px 20px; /* Espaciado interno */
text-decoration: none; /* Elimina el subrayado del enlace */
border-radius: 20px; /* Bordes redondeados */
display: inline-flex; /* Para alinear el ícono y el texto */
align-items: center; /* Alinea verticalmente el contenido */
gap: 10px; /* Espacio entre el ícono y el texto */
margin-top: 20px; 
}


.chat-button i {
margin-right: 8px; /* Añadir espacio entre el icono y el texto */
}



.contact-button {
background-color: #7F0000;    /* Fondo de color burdeos */
color: white;                 /* Texto en color blanco */
padding: 10px 20px;           /* Relleno interno para el botón */
border-radius: 20px;          /* Bordes redondeados */
border: none;                 /* Sin borde */
display: flex;                /* Flexbox para alinear el ícono y el texto */
align-items: center;          /* Alineación vertical central */
justify-content: center;      /* Centrar contenido en el botón */
gap: 10px;                    /* Espacio entre el ícono y los números */
margin-top: 20px;             /* Margen superior añadido */
}

.contact-button i {
font-size: 1.5rem;            /* Tamaño del ícono */
color: white;                 /* Color blanco para el ícono */
}

.contact-numbers p {
margin: 0;                    /* Sin márgenes */
line-height: 1.2;             /* Ajuste de la separación de líneas */
}


/* Sección de preguntas frecuentes */
.faq-section {
text-align: center;   /* Centra el texto de la sección de preguntas frecuentes */
padding: 40px;        /* Agrega un espacio de 40 píxeles alrededor de todo el contenido de la sección */
background-color: white;  /* Fondo gris claro (#f5f5f5) */
margin: 0;            /* Elimina los márgenes */
}


.faq-section h2 {
font-size: 1.8rem;    /* Tamaño de la fuente para el título grande en la sección de contacto */
font-family: 'Roboto', sans-serif;  /* Establece la fuente específica Bebas Neue */
font-weight: normal; /* Cambia el peso de la fuente para que no sea negrita */
color: #7F0000;       /* Color rojo oscuro para el título */
font-weight: bold;    /* Aplica negrita al título */
font-weight: normal; 
}

.faq-section p {
margin-top: 10px;     /* Añade un espacio superior de 10 píxeles entre el párrafo y el título anterior */
color: #000;         /* Color del texto negro */
max-width: 800px;     /* Limita el ancho del párrafo a 700 píxeles */
margin-left: auto;    /* Centramos el párrafo horizontalmente */
margin-right: auto;   /* Centramos el párrafo horizontalmente */
font-family: 'Roboto', sans-serif;  /* Establece la fuente específica Bebas Neue */
font-size: 1.3rem;    /* Tamaño de la fuente para el título grande en la sección de contacto */
}

.faq-section ul {
list-style-type: disc;  /* Cambia el estilo de la lista a viñetas (discos) */
text-align: left;      /* Alinea el texto de la lista a la izquierda */
margin-top: 20px;      /* Añade un margen superior de 20 píxeles para separar la lista del texto anterior */
max-width: 600px;      /* Limita el ancho de la lista a 600 píxeles */
margin-left: auto;     /* Centra la lista horizontalmente */
margin-right: auto;    /* Centra la lista horizontalmente */
}

.faq-section ul li {
margin: 10px 0;       /* Añade un margen de 10 píxeles entre cada elemento de la lista */
}

.faq-section ul li a {
color: #007BFF;       /* Color azul (enlace estándar) para los enlaces dentro de la lista */
text-decoration: underline; /* Subraya los enlaces de la lista */
}
