/* Contenedor del logo y nombre del sistema */
.logo-contenedorGe {
  display: flex;
  align-items: center; /* Centra verticalmente */
  padding: 20px 20px 10px 5px; /* Ajusta los paddings: arriba, derecha, abajo, izquierda */
  background-color:  white; /* Fondo del contenedor */
  height: 60px; /* Altura total */
  border: 1px solid #e4e4e4; /* Borde inferior */ 
}

/* Columna del logo */
.logo-columnaGe {
  flex: 0 0 auto; /* Mantiene el tamaño del logo fijo */
  display: flex;
  align-items: center;
  margin-right: 10px; /* Espaciado entre el logo y el texto */
}

/* Tamaño del logo */
.logoGe {
  width: 60px; /* Ancho del logo */
  height: auto;
  transition: all 0.3s ease; /* Animación suave para el tamaño */
  margin-left: 10px; /* Mueve el logo hacia la derecha */
}

/* Columna del texto */
.texto-columnaGe {
  flex: 1; /* Ocupa todo el espacio disponible */
}

/* Estilo del texto del nombre del sistema */
.nombre-sistema {
  padding-top: 0px; /* Espacio superior interno adicional */
  font-size: 19px;
  color:#F9F5F0; /* Color del texto */
  font-weight: 600; /* Peso del texto más consistente */
  line-height: 1.4; /* Ajusta la altura de línea */
  text-transform: uppercase; /* Convierte el texto a mayúsculas */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1); /* Sombra suave negra */
}



/* Estilo cuando el menú está colapsado */
.barra-lateralGe.colapsado .logo-contenedorGe {
  justify-content: center; /* Centra todo horizontalmente */
}
.barra-lateralGe.colapsado .logoGe {
  width: 80px; /* Aumenta el tamaño del logo al colapsar */
  height: auto;
  margin-top: 5px; /* Ajusta la posición vertical */
  margin-left: 5px; /* Ajusta la posición horizontal */
  transition: all 0.3s ease; /* Animación suave para el tamaño */
}


.barra-lateralGe.colapsado .texto-columnaGe {
  display: none; /* Oculta el texto */
}



/* Barra lateral */
.barra-lateralGe {
  width: 250px;
  background-color: #870404; /* Color gris claro */
  height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000; /* Mayor prioridad */
  transition: width 0.3s ease;
}

.barra-lateralGe.colapsado {
  width: 80px; /* Solo muestra los íconos */
}

/* Estilos generales del menú */
.barra-lateralGe ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.barra-lateralGe li {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #f2f4f5;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.barra-lateralGe li:hover {
  background-color: #ecdccbd1;
}

.barra-lateralGe li a {
  text-decoration: none;
  color: #f0eeee;
  font-weight: 300;
  display: flex; /* Asegura que los iconos y texto estén alineados */
  align-items: center;
}

.barra-lateralGe li a:hover {
  color: white;
}

/* Icono del menú */
.icono-menu-hero {
  width: 20px;
  height: 20px;
  color: #e7e5ec;
  margin-right: 15px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.barra-lateralGe li:hover .icono-menu-hero {
  transform: scale(1.1); /* Escala el icono al pasar el cursor */
  color: white; /* Cambia el color al pasar el cursor */
}

/* Submenú desplegable */
.submenu {
  list-style: none;
  padding-left: 20px; /* Añade sangría para submenú */
  display: none; /* Ocultar el submenú por defecto */
  flex-direction: column; /* Los ítems del submenú se alinean en columna */
}

.menu-item.expanded .submenu {
  display: block; /* Mostrar el submenú cuando está expandido */
}

.submenu li {
  padding: 5px 0;
  font-size: 14px; /* Reducir tamaño de los ítems del submenú */
  color: #efeded;
}

.submenu li:hover {
  background-color: #2c3e50;
  color: white;
}

/* Icono para expandir/cerrar submenús */
.icono-expandir {
  margin-left: auto;
  font-size: 12px;
  cursor: pointer;
  color: #c8c4c4;
}

/* Ajustar la posición del icono del submenú */
.barra-lateralGe li i.icono-expandir {
  margin-left: auto;
  font-size: 12px;
  cursor: pointer;
  color: #848080;
}

/* Animación para expandir y colapsar el submenú */
.submenu {
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .barra-lateralGe {
    width: 80px; /* Colapsado por defecto en pantallas pequeñas */
  }
}
