.seccion-instituciones {
  text-align: center;
  padding: 50px 20px;
  background-color: white;
  max-width: 1480px;
  margin-left: 20px;
  margin-right: 20px;
}

.titulo-seccion {
  position: relative;
  font-size: 2rem;
  font-weight: 400;
  color: #D7852C;
  margin-bottom: 10px;
}



.descripcion-seccion {
  font-size: 1rem;
  color: #777575;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 40px;
  font-weight: 400;
}

.institucion-logo {
  padding: 10px;
  display: flex;
  justify-content: center;
  animation: fadeIn 1s ease-in-out;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}




.logo {
  width: 100%;
  max-width: 150px;
  height: auto;
}

.custom-arrow {
  font-size: 3rem; /* Tamaño de la flecha */
  color: #9e3e5b; /* Color de la flecha */
  background: none; /* Sin fondo */
  border: none; /* Sin borde */
}

.custom-arrow:hover {
  color: #f0f0f0; /* Color al pasar el mouse */
}

.slick-dots li button:before {
  color: #9e3e5b;
}

.adornos-circulo {
  width: 150px;
  height: 150px;
  background-color: rgba(158, 62, 91, 0.1); /* Color y transparencia */
  border-radius: 50%;
  position: absolute;
  top: -20px; /* Ajusta la posición */
  left: -40px; /* Ajusta la posición */
  z-index: -1; /* Envía el círculo al fondo */
}

.linea-decorativa {
  width: 300px;
  height: 2px;
  background-color: #9e3e5b; /* Color de la línea */
  position: absolute;
  top: 0;
  left: 50%; /* Centra la línea */
  transform: translateX(-50%); /* Centrado */
}
