/* Contenedor principal */
.testimonios-seccion {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  margin-left: 100px;
}

/* Título */
.testimonios-titulo {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold; /* Negrita */
  color: #7F1618;
}

.contenido-testimonio h3{
  font-size: 1.3rem;
  font-weight: 400;
}
.contenido-testimonio p{
  font-size: 1rem;
  text-align:justify;
}

/* Línea y estructura de timeline */
.timeline-container {
  display: grid;
  grid-template-columns: 1fr 50px 1fr; /* Tres columnas: izquierda, línea, derecha */
  width: 100%;
  align-items: start;
  position: relative;

  /* Animación inicial */
  opacity: 0;
  transform: translateY(50px); /* Todo el contenedor comienza movido hacia abajo */
  transition: opacity 4s ease, transform 4s ease;
}

/* Cuando el contenedor entra en el viewport */
.timeline-container.visible {
  opacity: 1;
  transform: translateY(0); /* Vuelve a su posición original */
}

/* Tarjetas */
.timeline-item {
  opacity: 0; /* Las tarjetas comienzan ocultas */
  transform: translateX(0); /* Están en su posición horizontal */
  transition: opacity 0.8s ease 0.2s; /* Todas se animan con un retraso */
}

.timeline-container.visible .timeline-item {
  opacity: 1; /* Todas se hacen visibles */
  transform: translateX(0); /* Sin desplazamiento horizontal */
}

/* Tarjeta de Juan: Baja ligeramente */
.contenido-testimonio2 {
  margin-top: 220px; /* Ajusta la tarjeta de Juan para que quede alineada al punto */
}

.contenido-testimonio2 p {
  text-align: justify;
  font-size: 1rem;
}

.contenido-testimonio2 h3{
  font-size: 1.3rem;
  font-weight: 400;
}

.contenido-testimonio3 h3{
  font-size: 1.3rem;
  font-weight: 400; 
}

.contenido-testimonio3 p{
 
  font-size: 1rem;
  text-align: justify;
}

/* Línea vertical */
.timeline-line {
  grid-column: 2;
  width: 3px;
  background-color: #571001;
  height: 700px; /* Ajusta la altura */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Espaciado uniforme entre los puntos */
}

.puntoa {
  width: 15px;
  height: 15px;
  background-color: #801f46;
  border-radius: 50%;
  border: 3px solid #801f46;
}

.tarjeta-testimonio {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  text-align: left;
}

.contenido-testimonio3{
  margin-top: -280px;
}

.foto-testimonio {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.contenido-testimonio {
  display: flex;
  text-align: justify;
  gap: 15px;
  flex-wrap: wrap;
}



/* Responsividad */
@media (max-width: 768px) {
  .timeline-container {
    grid-template-columns: 1fr; /* Una columna */
    gap: 100px;
  }

  .timeline-item {
    text-align: center;
  }

}
