/*src/ComponentesRestringidos/ModuloNoticias/ModuloNoticias.css*/
.modulo-noticias-container {
    padding: 20px;
    background-color: #f9f9f9;
    
  }
  
  h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10rem;
  }
  
  .boton-agregar {
    padding: 10px 20px;
    background-color: #AA2226;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .boton-agregar:hover {
    background-color: #f1cbcc;
    color: #333;
  }
  
  .lista-noticias {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .noticia-item {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .noticia-item h3 {
    margin: 0;
    color: #333;
  }
  
  .noticia-item p {
    color: #666;
  }
  
  .boton-editar, .boton-eliminar {
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .boton-editar {
    background-color: #E4A232;
    color: white;
  }
  
  .boton-eliminar {
    background-color:#D00404;
    color: white;
  }
  
  .boton-editar:hover {
    background-color: #007bb5;
  }
  
  .boton-eliminar:hover {
    background-color: #e31b0c;
  }
  
  /* Estilos del formulario */
  .formulario-noticia {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .formulario-noticia label {
    display: block;
    margin-bottom: 10px;
    color: #333;
  }
  
  .formulario-noticia input,
  .formulario-noticia textarea,
  .formulario-noticia select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .botones-formulario {
    display: flex;
    gap: 10px;
  }
  
  .boton-guardar {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .boton-cancelar {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .boton-guardar:hover {
    background-color: #45a049;
  }
  
  .boton-cancelar:hover {
    background-color: #e31b0c;
  }
  