/* NoticiaDetalle.css */

.noticia-detalle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
  }
  
 .noticia-detalle-card {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    
    width: 100%;
    box-sizing: border-box;
    margin: 0; /* Asegura que no haya márgenes externos */
    padding: 0; /* Elimina cualquier relleno adicional */
}

  
.noticia-detalle-imagen img {
  width: 100%;
  height: 650px;
  object-fit: cover;
}

  
  .noticia-detalle-contenido {
    padding: 20px;
    font-family: "Roboto", sans-serif;
  }
  
  .noticia-detalle-titulo {
    font-size: 2rem;
    color: #333;
    margin-bottom: 15px;
  }
  
  .noticia-detalle-fecha {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .noticia-detalle-descripcion {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #444;
  }
  