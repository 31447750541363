.seccion-recorrido {
  background: linear-gradient(135deg, rgba(87, 16, 1, 1), rgba(127, 22, 24, 0.8));

  padding: 60px 50px; 
  text-align: center;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}

.seccion-recorrido::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cpath fill='none' stroke='%23ffffff' stroke-width='4' d='M0 50 Q25 75 50 50 T100 50'/%3E%3C/svg%3E");
  background-size: 100px 100px;
  opacity: 0.1; /* Ajusta la opacidad para que el patrón de ondas sea más visible */
  z-index: -1;
  pointer-events: none;
}

.titulo-contenedor2 {
  text-align: center; /* Centra los títulos en el contenedor */
  margin-bottom: 20px;
  font-weight: 300;
}

.titulo-contenedor2 h1 {
  font-size: 1.8rem; /* Ajusta el tamaño según necesites */
  font-weight: 500;
  color: #FFC834;
  margin: 0;
}

.titulo-contenedor2 h2 {
  font-size: 1.1rem;
  font-weight: 200;
  color: #ECDCCB;
  margin-top: 10px;
  margin-left: 40px; /* Margen izquierdo */
  margin-right: 40px; /* Margen derecho */
}


.botones-contenedorRe {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.boton-descargarRe {
  background-color: #ECDCCB;
  color: #ffffff;
  border: none;
  padding: 15px 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 5px;
}

.boton-descargarRe:hover {
  transform: scale(1.05);
}

.boton-contacto {
  display: inline-block; /* Asegura que el botón se comporte como un bloque alineable */
  background-color: transparent;
  color: #E9DEC2;
  border: 1px solid #E9DEC2;
  padding: 30px 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
}

/* Centralización vertical dentro del contenedor */
.contenedor-boton-contacto {
  display: flex; /* Flexbox para la alineación */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 100%; /* Asegúrate de que el contenedor tenga una altura */
}


.boton-contacto:hover {
  background-color: rgba(255, 255, 255, 0.2);
  transform: scale(1.05);
}



/* Botón principal */
.boton-descargarRe {
  display: flex;
  flex-direction: column; /* Alinea texto e íconos en columna */
  align-items: center; /* Centra el contenido horizontalmente */
  justify-content: center; /* Centra el contenido verticalmente */
  background-color: #FFC834; /* Color de fondo del botón */
  color: #7F1618; /* Color del texto */
  padding: 15px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

/* Contenedor de íconos */
.iconos-redesRe {
  display: flex;
  justify-content: center; /* Centra los íconos horizontalmente */
  margin-top: 10px; /* Espaciado superior entre texto e íconos */
}

/* Estilo para cada ícono */
.iconos-redesRe i {
  font-size: 1.2rem; /* Tamaño de los íconos */
  color: #571001; /* Color de los íconos */
  margin: 0 3px; /* Espaciado lateral entre íconos */
  transition: transform 0.3s ease, color 0.3s ease; /* Animación suave */
}

/* Efecto hover para los íconos */
.iconos-redesRe i:hover {
  transform: scale(1.2); /* Aumenta ligeramente el tamaño */
  color:#efe9c2; /* Cambia a dorado al pasar el mouse */
}

/* Efecto hover en el botón completo */
.boton-descargarRe:hover {
  transform: scale(1.05); /* Crece todo el botón */
}







/* Adorno a la derecha (modifica top para mover hacia arriba) */
.adorno-ondulado3 {
  display: flex;
  position: absolute;
  right: 40px;
  top: 25px; /* Ajusta este valor para subir o bajar la onda derecha */
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  z-index: 10;
}

/* Adorno a la izquierda */
.adorno-ondulado2 {
  display: flex;
  position: absolute;
  left: 40px;
  bottom: 30px; /* Cambia si deseas mover hacia arriba o abajo la onda izquierda */
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  z-index: 10;
}

/* Estilo y animación para cada línea ondulada */
.onda2, .onda3 {
  fill: none;
  stroke: #d68aa2;
  stroke-width: 2;
  animation: moverOnda 2s infinite ease-in-out alternate;
}

/* Animación de movimiento */
@keyframes moverOnda {
  0% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(10px);
  }
}


