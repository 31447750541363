/*src/Componentes/PerfilesAutoridades/PerfilesAutoridades.css/
/* Contenedor principal para todo el documento */
.main2-container {
  width: 100%; /* Abarca todo el ancho disponible del documento */
  max-width: 100%; /* Elimina la restricción del ancho máximo para ocupar todo el ancho */
  margin: 0; /* Elimina el margen automático que centra el contenedor */
  padding: 0px; /* Elimina cualquier padding alrededor del contenedor */
  box-sizing: border-box; /* Incluye padding y border en el tamaño total del contenedor */
  height: 1820px; /* Establece una altura fija inicial de 1500px */
}

.titulo {
  text-align: center;           /* Centra el texto */
  margin-left: 100px;           /* Margen a la izquierda de 100px */
  font-family: 'Roboto', sans-serif;  /* Fuente Roboto */
  font-size: 2rem;              /* Tamaño de la fuente */
  color: #7F0000;               /* Color del texto */
  margin-bottom: -16px;         /* Espaciado inferior */
  text-transform: uppercase;    /* Convierte el texto a mayúsculas */

}


.descripcion {
  text-align: center;          /* Centra el texto */
  margin-left: 100px;          /* Margen a la izquierda de 100px */
  font-family: 'Roboto', sans-serif;  /* Fuente Roboto */
  font-size: 1.2rem;           /* Tamaño de la fuente */
  color: #666;                 /* Color gris oscuro para el texto */
  margin-bottom: 20px;         /* Espaciado inferior */
  text-transform: uppercase;   /* Convierte el texto a mayúsculas */
}



/* Estilos para la imagen */
.header-image {
  width: calc(94.5% + 100px); /* Incrementa el ancho de la imagen en 100px más allá del 100% */
  max-width: 4750px; /* Limita el ancho máximo a 5000px */
  height: 400px; /* Mantiene la altura fija en 350px para mantener la proporción */
  object-fit: cover; /* Hace que la imagen se recorte para llenar el contenedor sin distorsión */
  display: block; /* Elimina cualquier espacio en blanco debajo de la imagen */
}
 /* Triángulo decorativo izquierdo */
.triangle-left {
  position: absolute; /* Posiciona el triángulo de forma absoluta */
  top: 118px; /* Reduce el valor para subir el triángulo más arriba */
  left: 0; /* Coloca el triángulo en el lado izquierdo */
  width: 0; /* El ancho del triángulo es cero */
  height: 0; /* La altura del triángulo es cero */
  border-right: 1450px solid transparent; /* Define el ancho del triángulo */
  border-bottom: 385px solid rgba(74, 21, 21, 0.6); /* Define la altura y el color del triángulo */
  z-index: 2; /* Coloca el triángulo por encima de otros elementos */
  pointer-events: none; /* Evita la interferencia con la interacción del usuario */
}

  /* Estilo de la línea blanca */
  .linea-blanca {
    width: 100%; /* Ajusta al 100% del ancho del contenedor */
    height: 2px; /* Define el grosor de la línea */
    background-color: white; /* Color de la línea */
    margin: 10px 0; /* Margen superior e inferior para espaciar la línea */
  }
  

.plantilla-principal {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}


/* Estilos para cada perfil de autoridad */
.perfil-autoridad {
  display: flex; /* Usa Flexbox para alinear la imagen y la información de perfil en una fila */
  background-color: #FFFFFF; /* Define un color de fondo gris claro para cada perfil */
  padding: 20px; /* Añade un espacio interno de 20px alrededor del contenido de cada perfil */
  width: 80%; /* Define el ancho del contenedor del perfil como 80% del contenedor padre */
  align-items: center; /* Centra verticalmente los elementos dentro del contenedor de perfil */
  margin-left: 150px; /* Desplaza el contenedor 30px hacia la derecha */
}
/* Estilo de la imagen de perfil */
.perfil-imagen {
  width: 190px; /* Ajusta el ancho de la imagen para que sea más grande */
  height: auto; /* Ajusta la altura automáticamente para mantener la proporción de la imagen */
  object-fit: contain; /* Asegura que toda la imagen sea visible sin recortarla */
  margin-left: 20px; /* Añade un margen a la izquierda para separar la imagen del texto */
  border-radius: 0; /* Elimina la redondez de las esquinas de la imagen */
  order: 2; /* Coloca la imagen después del texto en el orden de flexbox */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Añade un ligero sombreado alrededor de la imagen */
  transition: transform 0.4s ease, box-shadow 0.4s ease; /* Añade animación al transform y sombra */
}

/* Efecto al pasar el cursor sobre la imagen */
.perfil-imagen:hover {
  transform: scale(1.05);      /* Aumenta ligeramente el tamaño de la imagen al pasar el cursor */
  
}



/* Estilos para la información de perfil */
.perfil-info {
  flex: 1;                     /* Permite que la información de perfil tome todo el espacio disponible */
  padding-right: 20px;          /* Añade un espacio de 20px al lado derecho del texto para separación */
  order: 1;                     /* Coloca el texto primero en el orden de flexbox */
  margin-top: -20px;            /* Ajusta el margen superior para subir el contenido */
}


body {
  font-family: 'Open Sans', sans-serif; /* Aplica la fuente 'Open Sans' a todo el documento */
  color: #333333; /* Establece un color de texto gris oscuro */
  font-size: 16px; /* Tamaño de fuente base para el cuerpo de texto */
  line-height: 1.6; /* Define el interlineado para mejorar la legibilidad */
}

/* Estilo del Nombre (Título Principal) */
.perfil-info h4 {
  margin: 0;
  font-size: 26px; /* Tamaño de fuente grande para el nombre */
  font-weight: 700; /* Usa el peso de fuente 700 para un estilo negrita */
  color: #333;                 /* Color del texto */
  margin-bottom: 10px; /* Añade un espacio debajo del subtítulo */
  margin-left: 50px;           /* Margen izquierdo de 50px */
 
}

/* Estilo del Cargo (Subtítulo) */
.perfil-info h5 {
  margin: 0;
  font-size: 20px; /* Tamaño de fuente para el cargo */
  font-weight: 700; /* Usa el peso de fuente 700 para estilo de negrita completa */
  color: #680b0b; /* Aplica un color gris oscuro */
  margin-bottom: 15px; /* Añade un espacio debajo del subtítulo */
  margin-left: 50px;           /* Margen izquierdo de 50px */

}


/* Estilo del Texto de Descripción (Parrafo) */
.perfil-info p { 
  margin-top: 10px;            /* Espacio superior para separar del título */
  font-size: 17.5px;             /* Tamaño de fuente del párrafo */
  font-weight: 400;            /* Peso de fuente normal para el párrafo */
  color: #333333;              /* Color gris oscuro */
  line-height: 1.6;            /* Espaciado de línea para mejorar la legibilidad */
  text-align: justify;         /* Justifica el texto */
  margin-left: 50px;           /* Margen izquierdo de 50px */
}

