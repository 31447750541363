/* Página general */
.programaBach-page {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  

}

/* Cabecera */
.programaBach-header {
  background: linear-gradient(to bottom, #ECDCCB, #7F1618);
  color: white;
  text-align: center;
  padding: 70px;
  padding-left: 60px;
  padding-right: 60px;
}

.programaBach-header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #741919;
}

.programaBach-header p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  padding-left: 100px; /* Padding izquierdo */
  padding-right: 100px; /* Padding derecho */
}


.nivelBach {
  background-color: #ffc834;
  color: #571001;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
}

.calificacionesBach {
  margin-top: 10px;
  font-size: 1rem;
}

/* Aprendizaje */
.aprendizajeBach {
  background: white;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}

.aprendizajeBach h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #7f1618;
}

/* Plan de estudios */
.planEstudiosBach {
  background: white;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}

.planEstudiosBach h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #7f1618;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

table th,
table td {
  text-align: left;
  padding: 10px;
  border: 1px solid #ddd;
}

table th {
  background-color: #e4dcc8; /* Color de fondo del encabezado */
  color: #7f1618; /* Color del texto */
  font-weight: bold;
}

table tr {
  background-color: transparent; /* Fondo transparente para las filas */
}

/* Requisitos */
.requisitosBach {
  background: white;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}

.requisitosBach h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #7f1618;
}

.requisitosBach ul {
  list-style-type: disc;
  margin-left: 20px;
}

.requisitosBach ul li {
  margin: 5px 0;
}

/* Descripción */
.descripcionBach {
  background: white;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}

.descripcionBach h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #7f1618;
}

.descripcionBach p {
  font-size: 1rem;
}
