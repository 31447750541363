  /* Sección BIENVENIDA */
  .seccion-bienvenida {
    position: relative; /* Se asegura de que los adornos se posicionen dentro de esta sección */
    width: 100%;
    display: flex;
    flex-direction: column; /* Organización vertical */
    align-items: center;
    padding: 70px 40px 50px 40px; /* Espaciado interno: arriba, derecha, abajo, izquierda */
    background-color:  #FFFFFF;
    font-family: 'Roboto', sans-serif;
   z-index: 10;
  }
  
  
  /* Título principal */
  .titulo-bienvenidaBa {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    color: #7a0101;
    font-family: 'Merriweather', serif;
  }
  
  /* Contenedor principal */
  .contenidobien {
    display: flex;
    justify-content: space-between; /* Espacio entre texto y video */
    align-items: center; /* Alinea verticalmente */
    width: 100%;
    max-width: 1300px; /* Ancho máximo */
    gap: 40px; /* Espaciado entre las columnas */
  }
  
  .texto-contenedorBi {
    max-width: 50%; /* Ocupa la mitad del espacio disponible */
    text-align: justify;
  
    /* Animación inicial */
    opacity: 0; /* Comienza invisible */
    transform: translateX(-100px); /* Comienza fuera del área desde la izquierda */
    transition: opacity 1.5s ease, transform 1.5s ease; /* Animación rápida */
  }
  
  /* Cuando el contenedor entra en el viewport */
  .texto-contenedorBi.visible {
    opacity: 1; /* Se vuelve visible */
    transform: translateX(0); /* Regresa a su posición original */
  }
  
  
  .texto-contenedorBi h3 {
    font-size: 1.8rem;
    font-weight: 700;
    color: #7F1618;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .texto-contenedorBi p {
    text-align: justify;
    font-size: 1rem;
  }
  
  .texto-contenedorBi h5 {
  margin-top: 10px;
    text-align: center;
  }
  
  .video-contenedorBi {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  
    /* Animación inicial */
    opacity: 0; /* Comienza invisible */
    transform: translateX(100px); /* Comienza fuera del área desde la derecha */
    transition: opacity 1.5s ease, transform 1.5s ease; /* Transición suave */
  }
  
  /* Cuando el contenedor entra en el viewport */
  .video-contenedorBi.visible {
    opacity: 1; /* Se vuelve visible */
    transform: translateX(0); /* Vuelve a su posición original */
  }
  
  
  /* Miniatura del video */
  .video-thumbnailBi {
    position: relative;
    cursor: pointer;
  }
  
  .video-imagen {
    width: 100%;
    max-width: 650px;
    border-radius: 4px;
    border: 2px solid #eaeaea;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  }
  
  /* Botón de reproducción */
  .play-button {
    position: absolute; /* Posición absoluta dentro del contenedor relativo */
    top: 50%; /* Alinea verticalmente al 50% del contenedor */
    left: 50%; /* Alinea horizontalmente al 50% del contenedor */
    transform: translate(-50%, -50%); /* Ajusta para centrar el botón */
    background-color: rgba(255, 255, 255, 0.9); /* Fondo blanco con opacidad */
    width: 90px; /* Ancho del botón */
    height: 90px; /* Altura del botón */
    border-radius: 50%; /* Hace el botón circular */
    display: flex; /* Flexbox para centrar el contenido dentro del botón */
    justify-content: center; /* Centra el ícono horizontalmente */
    align-items: center; /* Centra el ícono verticalmente */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para resaltar el botón */
  }
  
  .play-button span {
    font-size: 36px;
    color: #7a0101;
  }
  
  
  
  /* Modal del video */
  .video-modalBi {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .video-modal-contentBi {
    position: relative;
    max-width: 90%; /* Ancho máximo del modal */
    max-height: 90%; /* Altura máxima del modal */
    width: 80%; /* Ancho inicial */
    height: auto;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 30px;
    color: white;
    cursor: pointer;
  }
  
  
  /* Responsividad */
  @media (max-width: 768px) {
    .contenidobien {
      flex-direction: column; /* Apila texto y video */
      gap: 20px; /* Reduce el espaciado */
    }
  
    .titulo-bienvenidaBa {
      font-size: 2rem; /* Ajusta el tamaño del título */
    }
  
    .texto-contenedorBi {
      max-width: 100%; /* Ocupa todo el ancho */
      margin-bottom: 50px; /* Agrega margen inferior */
  
    }
  
    .video-thumbnailBi {
      max-width: 100%; /* Asegura que el video ocupe todo el ancho */
    }
  }
  
  @media (max-width: 480px) {
    .titulo-bienvenidaBa {
      font-size: 1.8rem;
      margin-bottom: 20px;
    }
  
    .play-button {
      width: 70px;
      height: 70px;
    }
  
    .play-button span {
      font-size: 30px;
    }
  }