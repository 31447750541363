@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Contenedor principal */
.menu-contenedor4 {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: linear-gradient(to bottom,#ECDCCB 10%, #571001 60%);

}

.header-image-containerNu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px; /* Altura de la sección */
  background: linear-gradient(to bottom, #ECDCCB 10%, #571001 60%);
}

.overlayNo {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Separa el texto e imagen */
  width: 100%;
  padding: 0 50px; /* Espaciado horizontal */
}

.texto-contenedor {
  max-width: 50%; /* Limita el ancho del texto */
  color: white;
  margin-right: 20px;
  margin-left: 40px; /* Ajusta la posición hacia la derecha */
}

.imagen-derecha {
  width: 35rem; /* Limita el ancho de la imagen */
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagen-derecha img {
  width: 100%; /* La imagen ocupa todo el contenedor */
  height: auto;
  border-radius: 10px; /* Bordes redondeados */
}

.subtitulo {
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #FFDD57;
  margin-bottom: 10px;
}

.titulo-degradado2 {
  font-size: 40px;
  font-weight: 700;
  color: #f6f7dc;
  margin: 12px 0;
  font-family: 'Roboto Slab', sans-serif;

}

.texto-contenedor p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #F5E1DA;
  margin-bottom: 30px;
}

.btn-video {
  background-color:#E4A232;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s;
}

.btn-video:hover {
  background-color: #eed3a4;
}

.fa-play-circle {
  margin-left: 5px;
}

/* Modal de video */
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Fondo negro translúcido */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-modal-contentNo {
  position: relative;
  width: 80%; /* Ajusta el tamaño del video */
  max-width: 1000px; /* Tamaño máximo para pantallas grandes */
  background-color: #000;
  border-radius: 5px;
  overflow: hidden;
}

.video-modal-contentNo video {
  width: 100%;
  height: auto;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  z-index: 1000;
}

/* Animaciones */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Sección de historia++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
.historia-container {
  width: 100%; /* Ocupa todo el ancho */
  margin: -100px 0 0; /* Espaciado superior negativo */
  padding: 50px 60px; /* Espaciado interno */
  text-align: left; /* Alineación del texto */
  background-color: #F9FAFB; /* Fondo claro */
  position: relative; /* Posicionamiento para los adornos */
  z-index: 2; /* Capas superiores */
}

/* Animación de entrada del título */
.titulo-animado {
  animation: fadeInDown 1.2s ease-in-out;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animación de entrada del texto */
.texto-animado {
  animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Título principal */
.titulo-principalSo {
  font-size: 2rem; /* Tamaño grande */
  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center; /* Centrado */
  font-family: 'Roboto', sans-serif;
  margin-bottom: 40px;
  margin-top: -10px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); /* Sombra suave */
  animation: fadeInUp 1s ease-in-out; /* Animación */
}

/* Tarjeta de historia */
.tarjeta-historia {
  display: flex; /* Diseño flexible */
  flex-direction: row; /* Alineación horizontal */
  background-color: #F9FAFB;
  overflow: hidden; /* Oculta desbordes */
  max-width: 1500px; /* Máximo ancho */
  border-radius: 8px; /* Esquinas redondeadas */
  gap: 20px; /* Espaciado interno */
  margin: auto; /* Centra la tarjeta */
}

.contenido-tarjetaNo {
  padding: 20px; /* Espaciado interno */
  flex: 1; /* Ocupa más espacio */
  max-width: 700px; /* Ancho máximo */
  background-color: #F9FAFB;
  border-radius: 8px; /* Esquinas redondeadas */
}

.subtitulo-historia {
  font-size: 1.8rem;
  font-weight: 700;
  color: #7F1618;
  margin-bottom: 20px;
  text-align: center;

}

.texto-justificadoNo {
  text-align: justify; /* Justifica el texto */
}

/* Imagen de la historia */
.imagen-historia {
  width: 45%; /* Ocupa el 45% del contenedor */
  height: 450px; /* Altura fija */
  object-fit: cover; /* Ajusta imagen */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin-left: 60px; /* Espaciado izquierdo */
  border-radius: 8px; /* Esquinas redondeadas */
}

.imagen-historia:hover {
  transform: scale(1.05); /* Zoom al pasar el mouse */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Sombra */
}

/* Adorno de puntos redondos */
.adorno-puntosredondosSo {
  position: absolute;
  top: -40px;
  right: 10px;
  width: 350px;
  height: 350px;
  background-image: radial-gradient(circle, #24031c 1px, transparent 1px);
  background-size: 10px 10px;
  opacity: 0.4; /* Transparencia */
  border-radius: 50%; /* Circular */
  z-index: -1; /* Detrás del contenido */
  
}

/* Círculo decorativo */
.circulo-decorativoHi {
  position: absolute;
  bottom: -100px;
  left: -80px;
  width: 300px;
  height: 300px;
  background-color: rgba(94, 3, 73, 0.15); /* Púrpura claro */
  border-radius: 50%; /* Circular */
  animation: moverCirculo 8s infinite ease-in-out;
  z-index: -1; /* Detrás del contenido */
}

@keyframes moverCirculo {
  0% { transform: translate(0, 0); }
  50% { transform: translate(30px, -30px); }
  100% { transform: translate(0, 0); }
}

/* Responsividad */

/* Pantallas medianas */
@media (max-width: 992px) {
  .tarjeta-historia {
    flex-direction: column; /* Cambia a diseño vertical */
    gap: 15px; /* Reduce espacio */
  }

  .imagen-historia {
    width: 100%; /* Ocupa todo el ancho */
    height: 300px; /* Reduce altura */
    margin-left: 0; /* Elimina margen */
  }

  .contenido-tarjetaNo {
    max-width: 100%; /* Ocupa todo el ancho */
  }
}

/* Pantallas pequeñas */
@media (max-width: 768px) {
  .titulo-principalSo {
    font-size: 1.5rem; /* Reduce el tamaño del título */
  }

  .subtitulo-historia {
    font-size: 1.3rem; /* Reduce subtítulos */
  }

  .imagen-historia {
    height: 200px; /* Reduce más la altura */
  }

  .historia-container {
    padding: 30px 20px; /* Reduce el padding */
  }

  .adorno-puntosredondosSo, .circulo-decorativoHi {
    display: none; /* Oculta los adornos para pantallas pequeñas */
  }
}

/* Pantallas muy pequeñas */
@media (max-width: 480px) {
  .contenido-tarjetaNo {
    padding: 15px; /* Reduce padding */
  }

  .texto-justificadoNo {
    font-size: 0.9rem; /* Reduce el tamaño del texto */
  }
}


/* Secciones de Misión, Visión y Propósito************************************************************************** */
.ContenedorMisionVision {
  width: 100%; /* Ocupa todo el ancho */
  background: linear-gradient(to bottom, #741919, #C94B4D);

  text-align: justify; /* Alineación del texto */
  font-family: 'Roboto', sans-serif;
  padding-top: 60px; /* Espaciado superior */
  position: relative;
  overflow: hidden; /* Oculta desbordes de adornos */
  height: auto; /* Ajusta la altura automáticamente */
}

/* Título */
.titulo-estrategico {
  text-align: center; /* Centrado */
  margin-bottom: 20px; /* Espaciado inferior */
}

.subtitulo-pequeno {
  font-size: 1rem; /* Tamaño pequeño */
  background: #ECDCCB; /* Gradiente de color */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Solo el texto tiene color */
  font-family: 'Roboto', sans-serif;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); /* Sombra del texto */
  font-weight: 300; /* Peso de fuente ligero */
}

.declaracion-titulo {
  font-size: 1.8rem;
  font-weight: 700;
  color: #ECDCCB;
  margin-bottom: 20px;
  text-align: center;
}

/* Contenedor de las tarjetas */
.declaracion-container {
  display: flex; /* Diseño flexible */
  justify-content: space-between; /* Distribución uniforme */
  gap: 20px; /* Espaciado entre tarjetas */
  padding: 40px; /* Espaciado interno */
  margin: 0 auto; /* Centrado horizontal */
  flex-wrap: wrap; /* Las tarjetas se apilan en pantallas pequeñas */
}

/* Tarjeta */
.declaracion-tarjeta {
  display: flex; /* Diseño flexible */
  flex-direction: column; /* Alineación vertical */
  justify-content: center; /* Centrado vertical */
  background-color: white; /* Fondo blanco */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
  padding: 20px; /* Espaciado interno */
  flex: 1; /* Ajuste proporcional */
  min-width: 250px; /* Ancho mínimo */
  max-width: 400px; /* Ancho máximo */
  text-align: center; /* Centrado del texto */
  font-family: 'Roboto', sans-serif;
  transition: transform 0.3s, box-shadow 0.3s; /* Animación */
}

.declaracion-tarjeta:hover {
  transform: scale(1.05); /* Zoom al pasar el ratón */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Sombra más intensa */
}

/* Icono de cada tarjeta */
.icono-container {
  position: relative; /* Posicionamiento relativo */
  width: 7em; /* Tamaño del contenedor */
  height: 7em; /* Altura */
  display: flex; /* Diseño flexible */
}

.icono-circuloX, .icono-circuloY, .icono-circuloZ {
  width: 80%; /* Tamaño relativo al contenedor */
  height: 80%;
  border-radius: 50%; /* Forma circular */
  display: flex;
  justify-content: center;
  align-items: center;
}

.icono-circuloX {
  background-color: #D7852C; /* Color púrpura claro */
}

.icono-circuloY {
  background-color:#D7852C; /* Color verde claro */
}

.icono-circuloZ {
  background-color: #D7852C; /* Color azul claro */
}

.icono-svg {
  fill: white; /* Color del ícono */
  width: 3.5em; /* Tamaño del ícono */
  height: 3.5em;
}

/* Sección de valores */
.valores-section h4 {
  font-size: 1.5rem; /* Tamaño mediano */
  margin-bottom: 15px; /* Espaciado inferior */
  color: #650e1f; /* Color púrpura oscuro */
  text-align: center; /* Centrado */
}

.valores-section p {
  font-size: 1rem; /* Tamaño estándar */
  color: #333; /* Gris oscuro */
  line-height: 1.6; /* Mejora legibilidad */
  text-align: justify; /* Justifica el texto */
}

/* Adorno 2 */
.adorno-circulos-concentricos {
  position: absolute; /* Posicionamiento */
  width: 200px; /* Tamaño del adorno */
  height: 200px;
  border-radius: 50%; /* Circular */
  background: radial-gradient(circle, rgba(254, 253, 255, 0.2) 10%, transparent 10%),
              radial-gradient(circle, rgba(244, 242, 245, 0.25) 10%, transparent 10%);
  background-size: 25px 25px; /* Espaciado entre círculos */
  animation: girar 8s linear infinite; /* Rotación */
}

.adorno-circulos-concentricos-1 { 
  top: 10%; 
  left: 10%; 
}

.adorno-circulos-concentricos-2 { 
  bottom: 5%; 
  right: 1%; 
}

@keyframes girar {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Responsividad */

/* Pantallas medianas */
@media (max-width: 992px) {
  .declaracion-container {
    flex-direction: column; /* Alineación vertical */
    align-items: center; /* Centrado */
  }

  .declaracion-tarjeta {
    width: 100%; /* Ocupa todo el ancho */
    max-width: 500px; /* Límites */
    margin-bottom: 20px; /* Espaciado inferior */
  }
}

/* Pantallas pequeñas */
@media (max-width: 768px) {
  .titulo-estrategico {
    font-size: 1.5rem; /* Título más pequeño */
  }

  .declaracion-container {
    gap: 15px; /* Reduce el espaciado */
  }

  .valores-section h4 {
    font-size: 1.2rem; /* Títulos más pequeños */
  }

  .valores-section p {
    font-size: 0.9rem; /* Texto reducido */
  }

  .ContenedorMisionVision {
    padding-top: 30px; /* Reduce el espaciado */
  }
}

/* Pantallas muy pequeñas */
@media (max-width: 480px) {
  .icono-container {
    width: 5em; /* Icono más pequeño */
    height: 5em;
  }

  .icono-svg {
    width: 2.5em; /* Ajusta el ícono */
    height: 2.5em;
  }

  .declaracion-tarjeta {
    padding: 15px; /* Reduce padding */
  }

  .subtitulo-pequeno {
    font-size: 0.8rem; /* Texto reducido */
  }
}

/* Fondo de la sección Autoridades con adornos *************************************************************************************/
.seccion-autoridadesBi {
  position: relative;
  padding: 40px 20px;
  text-align: center;
  max-width: 2200px;
  overflow: hidden;
  background-color: #F9FAFB;
}

/* Título de la sección */
.titulo3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #571001;
  margin-bottom: 20px;
  text-align: center;

}



@keyframes growLine {
  from {
    width: 0;
  }
  to {
    width: 150px;
  }
}

/* Descripción debajo del título */
.descripcion2 {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  color: #716f6f;
  line-height: 1.6;
  margin: 0 auto 40px auto;
  max-width: 1200px;
  font-weight: 300;
}

/* Contenedor de tarjetas de autoridades */
.contenedor-autoridades {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Permite que las tarjetas se apilen en pantallas pequeñas */
}

/* Tarjeta de autoridad */
.tarjeta-autoridad {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #E9DEC2;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 400px;
  perspective: 1000px;
}

.tarjeta {
  width: 400px;
  height: 500px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.tarjeta-autoridad:hover .tarjeta {
  transform: rotateY(180deg);
}

.tarjeta-frontal,
.tarjeta-trasera {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.tarjeta-frontal {
  z-index: 2;
  background-color: #571001;
  color: #ecf0f1;
}

.tarjeta-trasera {
  background: linear-gradient(135deg, #571001, #ECDCCB);
  color: #FFFFFF;
  transform: rotateY(180deg);
  text-align: center;
}

.tarjeta-trasera p {
  font-size: 0.9rem;
  font-weight: 300;
  color: #FFFFFF;
  line-height: 1.4;
  text-align: justify;
}

.imagen-contenedor {
  width: 400px;
  height: 400px;
  overflow: hidden;
  margin-top: -50px;
}

.imagen-autoridad {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.nombre-autoridad {
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
  color: white;
  padding: 10px 0;
}

.cargo-autoridad {
  font-size: 0.9rem;
  color: #949191;
  text-align: center;
  margin-top: -8px;
}

/* Adornos adicionales */


/* --- Responsividad --- */

/* Pantallas medianas */
@media (max-width: 992px) {
  .contenedor-autoridades {
    gap: 15px; /* Reduce el espacio entre tarjetas */
  }

  .tarjeta-autoridad {
    width: 300px; /* Reduce el ancho */
  }

  .tarjeta {
    height: 450px; /* Ajusta la altura */
  }

  .descripcion2 {
    max-width: 90%; /* Reduce el ancho del texto */
    font-size: 1rem; /* Reduce ligeramente el tamaño del texto */
  }
}

/* Pantallas pequeñas */
@media (max-width: 768px) {
  .descripcion2 {
    font-size: 0.9rem; /* Tamaño menor de fuente */
  }

  .tarjeta-autoridad {
    width: 100%; /* Ocupa todo el ancho */
    max-width: 400px; /* Ancho máximo ajustado */
    padding-left: 15px; /* Padding izquierdo */
    padding-right: 15px; /* Padding derecho */
}


  .tarjeta {
    height: 450px; /* Ajusta la altura */
    width: 400px; /* Aumenta la anchura */
  }

  .tarjeta-trasera  {
     /* Padding uniforme en todos los lados */
    line-height: 1; /* Mejora la legibilidad */
    font-size: 0.8rem; /* Tamaño de letra adecuado */
  }

  .imagen-contenedor {
    height: 300px; /* Ajusta el tamaño de la imagen */
  }
}

/* Pantallas muy pequeñas */
@media (max-width: 480px) {
  .titulo3 {
    font-size: 1.5rem; /* Reduce el tamaño del título */
  }

  .descripcion2 {
    font-size: 0.8rem; /* Texto más pequeño */
  }

  .tarjeta-autoridad {
    max-width: 250px; /* Reduce el ancho máximo */
  }

  .tarjeta {
    height: 350px; /* Ajusta la altura */
  }

  .imagen-contenedor {
    height: 250px; /* Tamaño más pequeño para imágenes */
  }

  .adorno-puntosAu {
    height: 80px; /* Reduce el tamaño del adorno */
  }
}


/*seccion final*************************************************************************************************/
.stats-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 80px 20px; /* Aumenta el padding vertical (arriba y abajo) */
  background: linear-gradient(90deg, #741919, #ECDCCB);

  color: #fff;
  font-family: 'Roboto', sans-serif;
}


.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.stat-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  opacity: 0.8; /* Hacerlo ligeramente transparente */
}

.stat-number {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
}

.stat-label {
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ddd; /* Color gris claro */
}
