/* Estilos para las calificaciones */

.calificaciones-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  h2 {
    color: #333;
    font-family: Arial, sans-serif;
    margin-bottom: 20px;
  }
  
  .calificaciones-tabla {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  .calificaciones-tabla th,
  .calificaciones-tabla td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .calificaciones-tabla th {
    background-color: #f2f2f2;
  }
  
  .aprobado {
    background-color: #e0f7e9;
  }
  
  .reprobado {
    background-color: #f9e0e0;
  }
  
  .aprobado td {
    color: green;
  }
  
  .reprobado td {
    color: red;
  }
  