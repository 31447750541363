/* Sección del banner */
.seccion-bannerSe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 70px 150px 70px; /* Arriba, Derecha, Abajo, Izquierda */
  background: linear-gradient(to bottom,#ECDCCB 10%, #571001 60%);
  position: relative;
  overflow: hidden;
  max-height: 600px;
}



.seccion-bannerSe::before {
  content: ""; 
  position: absolute;
  top: 50%; /* Centra verticalmente el círculo */
  left: 50%; /* Centra horizontalmente el círculo */
  transform: translate(40%, -45%); /* Ajusta el origen para centrarlo */

  z-index: 1; 
  background: ( rgba(223, 222, 222, 0.682) 8%, transparent 8%); /* Puntos dentro del círculo */
 
}

.seccion-bannerSe > * {
  position: relative;
  z-index: 5; /* Asegura que el contenido esté sobre el pseudo-elemento */
}


/* Contenido del banner (texto e imagen) */
.banner-contenidoSe {
margin-top: 200px;
  flex: 1;
  max-width: 70%;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.banner-subtituloSe {
  font-size: 40px;
  font-weight: 700;
  color: #f6f7dc;
  margin: 12px 0;
  font-family: 'Roboto Slab', sans-serif;
}

.banner-descripcionSe {
  font-size: 18px;
  text-align: left;
  color: #E9DEC2;
  margin: 20px 0;
  font-family: 'Roboto';
}

.bienvenidaSe h2 {
  text-transform: uppercase;
  color: #FFDD57;
  margin-bottom: 15px;
  font-size: 1.4rem;
  letter-spacing: 1px;
}

/* Botones */
.banner-botonesSe {
  display: flex;
  gap: 15px;
}

.btn {
  margin-top: 20px;
  padding: 12px 24px;
  font-size: 17px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-empezarBa {
  background-color: #FFC834;
  color: black;
}

.btn-empezarBa:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  background-color: #D7852C;
  color: #FFFFFF;
}

.btn-cursosBa {
  background-color: #ECDCCB;
  color: black;
  border: 2px solid #ECDCCB;
}

.btn-cursosBa:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  background-color: #D7852C;
  color: #FFFFFF;
}

/* Imagen */
.banner-imagen {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.imagen-portada {
  margin-top: 250px;
  max-width: 750px;
  border-radius: 10px;
  
}

/* Animaciones */
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.slide-in-right {
  animation: slideInRight 0.5s forwards;
}

.slide-out-left {
  animation: slideOutLeft 0.5s forwards;
}

/* Responsividad */
@media (max-width: 768px) {
  .banner-contenidoSe {
    max-width: 100%;
    text-align: center;
  }

  .imagen-portada {
    max-width: 300px;
  }
}
