/* Encabezado general */
.encabezado {
  font-family: Arial, sans-serif;
  color: #ffffff;
  position: relative;
  z-index: 10;
}

/* Barra superior (más angosta) */
.encabezado-superior {
  background-color: #571001; /* Granate */
  display: flex;
  justify-content: space-between;
  align-items: center; /* Centra verticalmente */
  padding: 10px 30px 10px 30px; /* Arriba, Derecha, Abajo, Izquierda */
  font-size: 13px;
  height: 45px; /* Altura fija */
}


/* Información de contacto */
.encabezado-superior .info-contacto span {
  margin-right: 30px;
  color: #ECDCBB; /* Beige claro */
}

/* Redes sociales (horizontal) */
.redes-sociales {
  display: flex; /* Distribuye los íconos horizontalmente */
  gap: 15px; /* Espacio entre íconos */
  padding-top: 16px; /* Espacio superior */
  
}


.redes-sociales a .icon {
  color: #ECDCBB; /* Beige claro */
  font-size: 20px; /* Tamaño ajustado */
  transition: color 0.3s ease;
}

.redes-sociales a .icon:hover {
  color: #FFC834; /* Amarillo */
}


/* Barra principal */
.encabezado-inferior {
  background-color: #7F1618; /* Rojo oscuro */
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px; /* Ajusta la altura según lo que necesites */
  padding: 10px 30px 10px 20px; /* Arriba, Derecha, Abajo, Izquierda */
}


.logo-imagen {
  width: 95px;
  margin-left: 0px; /* Ajusta el valor según lo necesites */
}


/* Menú de navegación */
.navegacion ul { 
  display: flex;
  list-style: none;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.navegacion {
  font-family: Arial, sans-serif; /* O la fuente que uses */
  font-size: 14px; /* Tamaño consistente */
  color: #ECDCBB; /* Color consistente */
  text-decoration: none;
  padding-left: 600px; /* Padding izquierdo añadido */
}

/* Aplica transform al hover de los enlaces */
.navegacion a {
  font-family: Arial, sans-serif; /* Fuente */
  font-size: 14px; /* Tamaño */
  color: #ECDCBB; /* Color */
  text-decoration: none;
  
}

.navegacion a:hover {
  
  color: #FFC834; /* Cambia el color al amarillo */
  transition: background-color 0.3s ease, transform 0.2s ease;
  transform: scale(1.09); /* Aumenta ligeramente el tamaño */
}


/* Estilos del contenedor del botón */
.barra-divisoria {
  margin: 0 0px; /* Espaciado entre el icono y el texto */
  color: #ECDCBB; /* Color de la barra, ajusta según tu diseño */
  display: flex;
  width: 3px; /* Define el grosor de la barra */
  height: 24px; /* Altura de la barra */
  align-items: center; /* Alinear verticalmente con el texto */
  font-size: 17px; /* Tamaño consistente con los íconos */
}

.contenedor-boton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* Botón "Ingresar" */
.btn-ingresar {
  display: flex;
  align-items: center;
  gap: 8px; /* Espaciado entre el ícono y el texto */
  text-decoration: none;
  font-family: Arial, sans-serif; /* O la fuente que uses */
  font-size: 14px; /* Tamaño consistente */
  color: #ECDCBB; /* Color consistente */
  font-weight: 300;
  padding: 10px 15px;
  margin-right: 0px; /* Margen derecho añadido */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Ícono dentro del botón */
.icono-ingresar {
  font-size: 18px; /* Tamaño del ícono */
  display: flex;
  align-items: center;
}

/* Hover para el botón */
.btn-ingresar:hover {
  color: #FFC834; /* Azul más oscuro */
  transform: scale(1.05); /* Aumenta ligeramente el tamaño */
}

/* Activo (al hacer clic) */
.btn-ingresar:active {
  
  transform: scale(1); /* Vuelve al tamaño original */
}


/* Menú hamburguesa */
.menu-hamburguesa {
  display: none;
  font-size: 24px;
  color: #ECDCBB;
  cursor: pointer;
}

/* Contenedor de la pestaña con la flecha */
.menu-dropdown {
  position: relative; /* Define el contexto para el posicionamiento absoluto */
}

/* Dropdowns */
.menu-dropdown .dropdown-content {
  background-color: #7F1618; /* Fondo rojo oscuro */
  position: absolute;
  top: 100%; /* Coloca el menú justo debajo del contenedor padre */
  left: 0; /* Alinea el menú con el borde izquierdo del padre */
  padding: 10px 0;
  box-shadow: 0 4px 6px rgba(229, 223, 223, 0.1);
  border-radius: 3px;
  display: none;
  min-width: 200px; /* Ancho mínimo */
  z-index: 1000; /* Asegura que el menú quede sobre otros elementos */
}

/* Mostrar el menú desplegable al hacer hover */
.menu-dropdown:hover .dropdown-content {
  display: block;
}

/* Flecha simple */
.dropdown-toggle {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.dropdown-toggle::after {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  border: solid #ECDCBB; /* Color de la flecha */
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transition: transform 0.3s ease; /* Animación suave */
  margin-left: 5px;
}

/* Flecha rotada hacia arriba al hacer hover */
.menu-dropdown:hover .dropdown-toggle::after {
  transform: rotate(-135deg);
}

/* Estilo de los elementos del menú desplegable */
.menu-dropdown .dropdown-content li {
  
  padding: 10px 20px; /* Espaciado interno */
  list-style: none; /* Elimina los puntos de lista */
  border-bottom: 1px solid #ECDCBB; /* Línea divisoria */
}

/* Elimina la línea del último elemento */
.menu-dropdown .dropdown-content li:last-child {
  border-bottom: none;
}

/* Estilo de los enlaces */
.menu-dropdown .dropdown-content li a {
  color: #ECDCBB; /* Color de texto */
  text-decoration: none; /* Sin subrayado */
  display: block; /* Ocupa toda la fila */
  transition: color 0.3s ease, background-color 0.3s ease;
}

.menu-dropdown .dropdown-content li a:hover {
  color: #FFC834; /* Amarillo */
  
}


/* Responsivo */
@media (max-width: 768px) {
  .menu-hamburguesa {
    display: block;
  }

  .navegacion {
    display: none;
    flex-direction: column;
    background-color: #7F1618;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 20px 0;
  }

  .navegacion.mostrar {
    display: flex;
  }

  .navegacion ul {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
}
