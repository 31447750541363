/* Botón flotante */
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .whatsapp-icon {
    width: 35px;
    height: 35px;
  }
  
  /* Cuadro de contacto */
  .contact-popup {
    position: fixed;
    bottom: 90px;
    right: 20px;
    background-color: #fff;
    width: 280px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .contact-popup h3 {
    margin: 0;
    font-size: 16px;
    color: #fff; /* Color del texto */
    background-color: #9C0404; /* Color de fondo */
    padding: 10px;
    border-radius: 8px 8px 0 0; /* Bordes redondeados solo en la parte superior */
    text-align: center;
  }
  
  
  .contact-popup p {
    font-size: 14px;
    color: #555;
    padding: 10px; /* Espaciado uniforme: arriba, derecha, abajo, izquierda */
  }
  
  .consulta-select {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .btn-enviar {
    width: auto; /* Ajusta el ancho al contenido del botón */
    padding: 10px 20px; /* Espaciado arriba/abajo e izquierda/derecha */
    background-color: #25d366;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    display: block; /* Para centrar el botón */
    margin: 10px auto; /* Centra el botón horizontalmente */
  }
  
  
  
  .btn-enviar:hover {
    background-color: #1da851;
  }
  