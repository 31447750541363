/* Contenedor principal para todo el documento */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Poppins:wght@400;700&family=Roboto+Slab:wght@400;700&display=swap');

.menu-contenedor5 {
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
  display: flex;
  flex-direction: column; /* Acomoda los elementos en columna */
  align-items: stretch; /* Asegura que los elementos secundarios ocupen todo el ancho */
  height: auto; /* Permite que la altura se ajuste automáticamente */
  min-height: 100vh; /* Asegura que al menos ocupe toda la pantalla */
  margin-bottom: 100px; /* Margen inferior */
}

/* Sección de introducción */
.seccion-noticias {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom,#ECDCCB 10%, #571001 60%);
  padding: 60px 70px;
  position: relative;
  overflow: hidden;
  height: 600px;
  padding-top: 100px;
}

.contenido-noticias {
  max-width: 50%;
}

.caso-uso {
  text-transform: uppercase;
  color: #FFDD57;
  margin-bottom: 15px;
  font-size: 1.4rem;
  letter-spacing: 1px;
}

.titulo-noticias {
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 25px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  font-family: 'Roboto Slab', serif;
}

.descripcion-noticias {
  font-size: 17px;
  color: #E9DEC2;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 30px;
}

.boton-noticias {
  background-color:#E4A232;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.boton-noticias:hover {
  background-color: #eed3a4;
  color: #333;
}

.imagen-noticias {
  position: relative;
  width: 55%;
  text-align: center;
  margin-top: 40px;
}

.imagen-noticias img {
  max-width: 85%;
}
/**********************************Sección Noticias*******************************************/

.contact-sectionPrin {
  padding-left:  120px; /* Espaciado interno */
  padding-right: 200px;
 
}
.noticias-titulo {
  font-size: 1.2rem; /* Tamaño de fuente */
  font-weight:400; /* Negrita */
  color: #571001; /* Color oscuro */
  margin-bottom: 10px; /* Espaciado inferior */
  padding: 10px 0; /* Espaciado superior e inferior */
 margin-top: 100px;
}


/* Selector de vista (Grid o Lista) */
.vista-selector {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -40px;
  margin-right: 5px;
  margin-bottom: 40px;
}

.vista-selector span {
  margin-right: 10px;
  font-weight: bold;
  color: #333333;
}

.vista-selector button {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 5px;
}

.grid-btn {
  color: #E4A232;
}

.list-btn {
  margin-top: 5px;
  color: #7F1618;
}

.noticias-container {
  display: grid;
  gap: 20px;
  background-color: #e5e7eb;
}

.grid-view {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  background-color:#ffffff ;
}

.list-view {
  grid-template-columns: 1fr;
}

.noticia-card {
  background-color: #ffffff; /* Fondo blanco */
  border-radius: 10px; /* Bordes redondeados */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Sombra más pronunciada */
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 400px; /* Ancho máximo */
  min-height: 450px; /* Altura mínima */
  margin: auto; /* Centrado */
}

.noticia-card:hover {
  transform: translateY(-5px); /* Movimiento hacia arriba */
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15); /* Sombra al pasar el mouse */
}

.noticia-categoria{
  margin-top: 40px;
  margin-left: 20px;
  color: #E4A232;
}
.noticia-titulo{
  font-weight: bold;
}
.noticia-imagen img {
  width: 100%;
  height: 250px; /* Altura fija para imágenes */
  object-fit: cover; /* Ajuste de imagen */
  border-bottom: 1px solid #e5e7eb; /* Línea divisoria */
}

.noticia-texto {
  padding: 20px; /* Espaciado interno */
  text-align: left;
}

.noticia-titulo {
  font-size: 1.2rem; /* Tamaño del título */
  color: #111827; /* Texto oscuro */
  margin-bottom: 10px;
}

.noticia-fecha {
  font-size: 0.9rem;
  color: #6b7280;
  margin-bottom: 10px;
}

.noticia-boton {
  background-color: #E4A232; /* Rojo oscuro */
  color: white;
  padding: 10px 20px;
  font-size: 0.9rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.noticia-boton:hover {
  background-color: #f6cfd0;
  color: #AA2226;
}
