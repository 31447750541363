/* Estilos generales */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  overflow-x: auto; /* Habilita desplazamiento horizontal */
  overflow-y: auto; /* Habilita desplazamiento vertical */
}

/* Contenedor principal */
.contenedor {
  display: flex;
  height: 100vh; /* Ocupa toda la altura de la pantalla */
  width: 100%; /* Ajusta al ancho total */
  margin: 0;
  padding: 0;
  position: relative; /* Para animaciones */
  overflow: visible; /* Permite que el contenido se desplace */
}

/* Imagen de fondo */
.imagen-fondo {
  position: absolute;
  top: 0;
  right: 0; /* Cambiado para evitar problemas */
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que la imagen cubra todo el área */
  z-index: -1; /* Envía el fondo detrás de todo */
  animation: slideInFromRight 1s ease-out forwards;
  overflow: visible;
}

/* Animación de entrada de la imagen desde la derecha */
@keyframes slideInFromRight {
  from {
    right: -100%; /* Comienza fuera del viewport por la derecha */
  }
  to {
    right: 0; /* Llega a su posición final ocupando todo el ancho */
  }
}

/* Logo y Bienvenida */
.logo-y-bienvenida {
  position: absolute;
  top: 29%;
  right: 20%; /* Posiciona el logo y texto al lado derecho */
  text-align: center;
  z-index: 1;
}

.logo-imagenLo {
  width: 230px; /* Tamaño del logo */
  height: auto;
  margin-bottom: 10px; /* Espacio debajo del logo */
}

.titulo-bienvenido {
  font-size: 2.5rem; /* Tamaño de fuente */
  font-weight: 400; /* Texto normal */
  color: #fff; /* Blanco */
  margin: 0;
  margin-top: -2.8rem;
}

/* Sección derecha */
.seccion-derecha {
  flex: 0.5; /* Ocupa la mitad del ancho */
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%); /* Inicialmente fuera del viewport (a la izquierda) */
  animation: slideInFromLeft 1.5s ease-out 1s forwards; /* Animación para que entre desde la izquierda */
}

/* Animación de entrada desde la izquierda */
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%); /* Comienza fuera del viewport (lado izquierdo) */
  }
  to {
    transform: translateX(0); /* Llega a su posición final (en la mitad) */
  }
}

/* Contenedor de roles */
.roles-container {
  margin-top: 3rem;
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  padding-bottom: 9rem;
}

/* Estilo para cada rol */
.rol {
  font-size: 1rem;
  color: #666;
  cursor: pointer;
  padding: 0.5rem 1rem;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

/* Rol activo (seleccionado) */
.rol.activo {
  color: #E4A232;
  border-bottom: 3px solid #E4A232;
}

/* Título del formulario */
.titulo-login {
  color: #870404;
  font-size: 1.8rem;
  margin-bottom: -0.5rem;
  text-align: center;
}

/* Texto debajo del título */
.texto-rol-seleccionado {
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
  color: #666;
}

.texto-rol-seleccionado strong {
  font-weight: 400;
  color: #E4A232; /* Destaca el rol seleccionado */
}

/* Formulario de inicio de sesión */
.seccion-derecha form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
}

/* Inputs del formulario */
.seccion-derecha input {
  margin-bottom: 1rem;
  padding: 0.8rem 0;
  font-size: 1rem;
  border: none;
  border-bottom: 2px solid #EDE7E6;
  outline: none;
  transition: border-color 0.3s ease;
}

/* Efecto al enfocar */
.seccion-derecha input:focus {
  border-bottom: 2px solid #E4A232;
}

/* Placeholder del input */
.seccion-derecha input::placeholder {
  color: #aaa;
  font-size: 1rem;
}

/* Checkbox del formulario */
.contenedor-checkboxLo {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.contenedor-checkboxLo input[type="checkbox"] {
  margin: 0;
  vertical-align: middle;
}

.contenedor-checkboxLo label {
  margin-left: 0.5rem;
  font-size: 1rem;
  color: #666;
}

/* Botón de ingresar */
.boton-registrarse {
  padding: 0.8rem;
  font-size: 1rem;
  background-color: #7F1618;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.boton-registrarse:hover {
  background-color: #f4bbbc;
  transform: scale(1.05);
  color: black;
}

/* Enlace de soporte */
.enlace-iniciar-sesion {
  font-size: 1rem;
  margin-top: 1rem;
  text-align: center;
}

.enlace-iniciar-sesion a {
  color: #D00404;
  text-decoration: none;
  font-size: 1rem;
}

.enlace-iniciar-sesion a:hover {
  text-decoration: underline;
}

/* Copyright */
.copyright {
  margin-top: 80px;
  font-size: 0.9rem;
  color: #969595;
  text-align: center;
  font-weight: 300;
}
