/* Barra Superior */
.barra-superior2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white; /* Fondo blanco */
  color: white;
  padding: 10px 20px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Sombra inferior */
  border-bottom: 1px solid #e4e4e4; /* Borde inferior */
  width: calc(100% - 0px); /* Ancho dinámico con el menú expandido */
  transition: margin-left 0.3s ease, width 0.3s ease; /* Transición suave */
}

.barra-superior2.colapsado {
  width: calc(100% - 80px); /* Ancho dinámico con el menú colapsado */
  margin-left: 80px; /* Mueve la barra con el menú colapsado */
}

/* Botón hamburguesa */
.toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  z-index: 3000;
  transition: margin-left 0.3s ease, opacity 0.3s ease;
}

/* Icono del botón hamburguesa */
.icono-hamburguesa {
  width: 24px;
  height: 24px;
  fill: #6b6b6b; /* Color del icono */
  transition: transform 0.3s ease; /* Efecto suave al pasar el cursor */
}

.icono-hamburguesa:hover {
  transform: scale(1.1); /* Efecto al pasar el cursor */
}

/* Posición del botón en estado colapsado */
.toggle-btn.colapsado {
  margin-left: -5px; /* Ajusta la posición hacia la izquierda */
}

/* Otros íconos */
.icono-wrapper svg {
  width: 24px;
  height: 24px;
  fill: #6b6b6b;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.icono-wrapper svg:hover {
  transform: scale(1.1);
}

/* Botón cuando el menú está expandido */
.barra-lateralGe:not(.colapsado) ~ .barra-superior2 .toggle-btn {
  opacity: 0; /* Ocultar botón hamburguesa */
  pointer-events: none; /* Deshabilitar clic */
}

/* Botón cuando el menú está colapsado */
.barra-lateralGe.colapsado ~ .barra-superior2 .toggle-btn {
  opacity: 1; /* Mostrar botón hamburguesa */
  pointer-events: auto; /* Habilitar clic */
  margin-left: 80px; /* Ajustar posición del botón en el menú colapsado */
}

.saludo {
  font-size: 17px;
  font-weight: 300;
  margin-left: 48%; /* Empuja el texto hacia la derecha */
  color: #6b6b6b; /* Color más claro para destacar */
}

.opciones-usuario {
  display: flex;
  align-items: center;
  gap: 15px; /* Espaciado uniforme entre los íconos */
}

/* Otros estilos mantienen su configuración previa */


.icono-wrapper {
  margin-right: 15px;
  position: relative;
}

.icono-grande {
  font-size: 24px;
  cursor: pointer;
  color: #6B6B6B; /* Gris claro */
  transition: transform 0.3s ease, color 0.3s ease;
}

.icono-grande:hover {
  transform: scale(1.1); /* Efecto hover */
  color: #ffffff; /* Destaca el ícono al pasar el mouse */
}

.notificaciones-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: #6B6B6B;
  border-radius: 50%;
  font-size: 12px;
  padding: 2px 6px;
  font-weight: bold;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Sombra para destacar */
}

/* Avatar del usuario */
.avatar-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease;
}

.avatar-container:hover {
  transform: scale(1.05); /* Efecto hover para el avatar */
}

.avatar-usuario {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Sombra alrededor del avatar */
}

.nombre-usuario {
  font-weight: 300;
  font-size: 17px;
  color: #6B6B6B; /* Color del texto */
}

/* Menú desplegable */
.dropdown-menu {
  position: absolute;
  top: 50px;
  right: 0;
  background-color:#6B6B6B;
  color: black;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Sombra */
  border-radius: 5px;
  z-index: 1000;
  min-width: 150px;
  animation: fadeIn 0.3s ease-in-out; /* Animación de aparición */
}

.dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.dropdown-menu li {
  padding: 8px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
  color: #333; /* Color del texto al pasar el mouse */
}

/* Animación de aparición */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
