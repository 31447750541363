.container-content {
  padding: 15px 15px 2px 15px; /* Padding: arriba, derecha, abajo, izquierda */
  width: 100%; /* Ajusta el ancho al contenedor */
  background-color: #f4f4f4;
  
}

.card-header {
  font-size: 17px;
  background-color: #f4e4df;
  color: #7a0b0b;
  padding: 10px 20px;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  font-weight: 300; /* Esto hará que la letra sea más delgada */
}

label { 
  font-size: 15px; /* Ajusta el tamaño de la fuente */
  color: #878585; /* Ajusta el color de la fuente */
  font-weight: 450; /* Ajusta el grosor de la fuente para que sea más delgada */
}

.buscar-estudiante-contenedor {
  margin: 20px 0; /* Añadir margen superior e inferior */
  padding: 20px; /* Añadir espacio interior */
  border: 0.5px solid #d7d4d4; /* Borde color plomo */
  border-radius: 4px; /* Bordes redondeados */

}



.card {
  box-shadow: 0px 4px 8px rgba(132, 129, 129, 0.1);
  border-radius: 0 0 8px 8px; /* Redondear solo las esquinas inferiores */
  padding: 1.5rem;
}

.card-body {
  padding: 0rem;
}

.form-control {
  padding: 2px 6px; /* Reducir el padding para hacer los campos más bajos */
  font-size: 12px;  /* Reducir el tamaño de la fuente */
  height: auto;     /* Asegurar que la altura sea automática */
  line-height: 1.1; /* Reducir el espaciado vertical interno */
  border-radius: 4px; /* Mantener bordes redondeados */
}


.toggle-icon {
  cursor: pointer;
  margin-left: auto; /* Alinear el ícono a la derecha dentro del recuadro */
}

.btn-info, .btn-success {
  padding: 8px 15px; /* Reducir el espacio interno para hacer los botones más compactos */
  width: auto; /* Asegurar que el ancho se ajuste automáticamente al contenido */
  display: inline-flex; /* Para alinear íconos y texto correctamente */
  align-items: center; /* Alinea el ícono con el texto verticalmente */
}

.btn-info i, .btn-success i {
  margin-right: 5px; /* Espacio entre el ícono y el texto */
  font-size: 16px; /* Tamaño del ícono */
}


.btn-info, .btn-success,.btn-primary {
  background-color: #17a2b8; /* Color del botón */
  padding: 8px 12px; /* Aumentar el padding para hacer el botón un poco más alto */
  font-size: 14px; /* Ajustar el tamaño de la fuente si es necesario */
  border-radius: 4px; /* Mantener bordes redondeados */
}

.btn-success {
  background-color: #00bfff; /* Color del botón */
}
.reporte-estudiantes-container .btn-info {
  background-color: #1abc9c;
  color: white;
}
.btn-primary {
  background-color: #3498db; /* Color del botón */
}



/* Hacer que los select tengan la misma altura que los input */
select.form-control {
  height: 38px; /* Ajusta la altura para que coincida con la altura de los input */
  padding: 6px 12px; /* Ajuste de padding para que el texto quede alineado */
  font-size: 16px; /* Ajusta el tamaño de la fuente si es necesario */
  line-height: 1.5; /* Ajusta la altura de línea */
  border-radius: 4px; /* Mantener bordes redondeados */
}

input.form-control {
  height: 38px; /* Asegura que los input también tengan la misma altura */
}

.custom-modal-header .btn-close {
  font-size: 0.50rem !important;  /* Reducir el tamaño del icono */
  width: 20px !important;         /* Ajustar el ancho */
  height: 20px !important;        /* Ajustar la altura */
  padding: 0.25rem !important;    /* Ajustar el padding */
}

.custom-box {
  border: 1px solid #f0eeee;  /* Borde gris */
  margin-bottom: 10px;         /* Separación debajo del recuadro */
  padding: 0;                  /* Elimina padding general */
  border-radius: 2px;         /* Bordes redondeados */
}

.form-fields {
  padding: 20px;               /* Aplicar padding solo a los campos */
}



.custom-button {
  background-color: #d4edf7;   /* Fondo azul claro */
  color: #2a5173;              /* Color del texto */
  border: none;                /* Sin borde */
  width: 100%;                 /* Que ocupe todo el ancho disponible */
  text-align: left;            /* Alinear el texto a la izquierda */
  font-size: 15px;             /* Tamaño de la fuente */
  border-radius: 0px;          /* Bordes sin redondear */
  font-weight: bold;           /* Texto en negrita */
  padding: 10px 0;             /* Espaciado interno en el eje vertical */
  margin: 0;  
  font-weight: 300; /* Esto hará que la letra sea más delgada */                 /* Sin margen */
}
.custom-button:hover {
  background-color: #bcdceb; /* Un azul más oscuro al pasar el ratón */
}

input.form-control, select.form-control {
  color: #878585; /* Color plomo para el texto */
}

input::placeholder, select::placeholder {
  color: #878585; /* Si el campo tiene placeholders, también aplicarles el color */
}

.form-control {
  font-size: 16px;  /* Aumenta el tamaño de la fuente a 14px */
  color: #878787;   /* Mantén el color plomo si lo prefieres */
}



/* Botones personalizados */
.btn-add-edit, .btn-add-close, .btn-cancel-action {
  padding: 8px 15px;  /* Ajusta el padding para que todos los botones sean consistentes */
  font-size: 15px;    /* Asegura que el tamaño de la fuente sea el mismo */
  line-height: 1.3;   /* Ajusta la altura de la línea para todos los botones */
  border-radius: 5px; /* Bordes redondeados para todos los botones */
  font-weight: 300;   /* Hace que las letras sean delgadas */
}

.btn-add-edit {
  background-color: #28a745 !important; /* Verde */
  color: white; /* Texto blanco */
}

.btn-add-close {
  background-color: #00bfff !important; /* Celeste */
  color: white !important; /* Texto blanco */
}

.btn-cancel-action {
  background-color: #dc3545 !important; /* Rojo */
  color: white !important; /* Texto blanco */
}

/* Reducir altura del footer */
.modal-footer {
  padding: 10px !important; /* Ajustar el padding para que el footer sea más bajo */
}
.modal-content{
  z-index: 2050 !important; /* Asegura que el modal esté al frente */
  margin-left: 100px;
}


.img-thumbnail {
  width: 150px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
}

.form-group label.btn {
  cursor: pointer;
}

.form-group p {
  font-size: 14px;
  color: #c6c6c6;
}


.estudiantes-wrapper {
  margin: 18px;
  padding: 5px;
  border: 1px solid #d8d7d7; /* Color plomo para el borde */
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(132, 129, 129, 0.1);
  width: 100%; /* Ancho al 100% */
  max-width: 1500px; /* Ajusta este valor según el diseño del recuadro superior */
  margin-left: auto;
  margin-right: auto;
}


.card1 {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}
.reporte-estudiantes-container .btn {
  margin-top: -15px;  /* Margen superior */
  margin-bottom: 20px;  /* Margen inferior */
}


.custom-table {
  width: 100%;
  margin-top: 10px;
}

.custom-table th, .custom-table td {
  text-align: center;
  vertical-align: middle;
}

.custom-table thead th {
  background-color: #f8f9fa;
  color: #737576;
  font-weight: 400;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th, .table-bordered td {
  border: 1px solid #dee2e6;
}

.acciones {
  display: flex;
  justify-content: space-evenly;
}

.btn-accion {
  margin-right: 5px;
  display: inline-block;
}

.btn-accion i {
  margin-right: 5px;
}
.custom-button2 {
  background-color: #4b0082; /* Color elegante, como índigo */
  color: #fff; /* Texto blanco */
  padding: 5px 20px; /* Ajusta el padding para reducir la altura */
  border-radius: 6px; /* Bordes redondeados */
  border: none; /* Elimina el borde por defecto */
  font-size: 14px; /* Tamaño de fuente más pequeño */
  transition: background-color 0.3s ease; /* Transición suave al cambiar el color */
}
.custom-button2:hover {
  background-color: #5d3b9e; /* Cambia el color al pasar el mouse */
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 10px;
  border-top: 1px solid #dee2e6;
}

.btn-paginacion {
  background-color: #007bff;
  color: white;
}

.select-registros, .buscar-input {
  width: auto;
  display: inline-block;
  margin-right: 10px;
  padding: 5px 10px;
}

.busqueda-y-registros {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.busqueda-y-registros label {
  margin: 0 10px;
}

.reporte-estudiantes-container {
  margin-top: 20px;
}


.archivo-preview {
  margin-bottom: 10px;
  text-align: center;
}

.archivo-preview img {
  display: block;
  margin: 0 auto;
}



