@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~@fortawesome/fontawesome-free/css/all.min.css';

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  
}

/* Contenedor principal */
.layout-container {
  display: flex;
  margin: 0;
  padding: 0;
}

/* Barra lateral (expandido y colapsado) */
.barra-lateral {
  width: 250px; /* Ancho expandido */
  transition: width 0.3s ease;
}

.barra-lateral.colapsado {
  width: 80px; /* Ancho colapsado */
}

/* Barra superior */
/* Barra superior */
.barra-superior2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  color: black;
  padding: 10px 20px;
 
  transition: margin-left 0.3s ease, width 0.3s ease; /* Animación suave */
  width: calc(100% - 250px); /* Ancho completo del contenido al expandir */
}

.barra-superior2.colapsado {
  width: calc(100% - 80px); /* Ancho completo del contenido al colapsar */
  margin-left: 80px; /* Ajuste del margen izquierdo */
}

/* Botón hamburguesa */




/* Contenedor del contenido principal */
.contenido-principalDa {
  flex-grow: 1;
  margin-left: 250px; /* Margen cuando el menú está expandido */
  transition: margin-left 0.3s ease; /* Suavidad al mover */
  background-color: #f4f4f4;
}

.contenido-principalDa.colapsado {
  margin-left: 80px; /* Margen cuando el menú está colapsado */
}

/* Ajuste de contenido interno */
.contenidoDa {
  background: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Botón hamburguesa */
.toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
  transition: margin-left 0.3s ease;
}

.toggle-btn.colapsado {
  margin-left: 0; /* Ajusta la posición del botón en el menú colapsado */
}

